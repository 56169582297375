import styled from "styled-components"
export const OurTeamCon = styled.div `
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
background-color:#F2f2f2;
padding:2rem 0;
`
export const OurTeamConWrapper = styled.div `
width:70%;

display:flex;
justify-content:space-between;
align-items:baseline;
flex-wrap:wrap;

`
export const SmallHeaderCon = styled.div `
width:70%;
margin:2rem 0;

`

export const SmallHeader = styled.h6 `
text-transform:uppercase;
color:#828282;
letter-spacing: 1.25px;
font-weight: normal;

`
export const ListCon = styled.div `
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:flex-start;
flex-wrap:wrap;
`
export const ListWrapper = styled.div `
display:flex;
align-items:center;
justify-content:space-between;
flex-wrap:wrap;

`
export const TeamMemberCon = styled.div `

position:relative;
width:calc(14rem + 1rem / 4);
height:14.5rem;
overflow:hidden;

`

export const MemberImg = styled.img `
object-fit:cover;
width:100%;
height:100%;
position:relative;
transition: all .4s ease-in-out; 
:hover{
    object-fit:cover;
transform:scale(1.041);
}
`
export const MemberInfoCon = styled.div `
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
position:absolute;
width:calc(100% - 3rem);
padding:.54rem 0;
background: rgba(0, 0, 0, 0.8);
top:69%;


h5{
    color:#fff;
    text-align:center;
}
p{
    color:#CCC;
    font-size:.7rem;
    font-weight:normal;
    padding-bottom:.2rem;
    text-align:center;
}
.memberLinkCon {
   color:#CCC;
   
}
.links{
    color:#CCC;
    
}


`