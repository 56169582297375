import { Fragment , useEffect,useState,useRef,useCallback} from "react";
//import {Box,Button,Layer} from "grommet"
import AboutSummary from "../../components/AboutSummary/AboutSummary";
import CarouselHeader from "../../components/carousel/CarouselHeader";
import Mantra from "../../components/Mantra/Mantra";
import Ideology from "../../components/Ideology/Ideology";
import ConnectWith from "../../components/ConnectWith/ConnectWith";
import EventsView from "../../components/EventsView/EventView";
import News from "../../components/News/News";
import SummitsSummary from "../../components/SummitsSummary/SummitsSummary";
import FooterOsh from "../../components/FooterOsh/FooterOsh";
import ParralaxHome from "../../components/Parralax/ParralaxHome";
import Partners from "../../components/Partners/Partners";
import GroupRight from "../../assets/images/group-right.png";
import ConnectL from "../../assets/images/connectL.png"
import ProjectBanner from "../../components/ProjectBanner/ProjectBanner";
import Modal from "../../Layout/Modal/Modal";
import summitImg from "../../assets/images/regsummit.jpg";
import Guide from "../../components/Guide/Guide";
import { useLocation ,useParams} from "react-router-dom";
const AboutContent='Science hardware encompasses the tools and machinery we use forscientific endeavors (for example, a microscope or environmental sensor). Open Science Hardware refers to science hardware that is open source - or free to use, change, study or distribute. By making science hardware open, more people can have access to the tools we use to do science” Open source hardware is hardware whose design is made publicly available so that anyone can study, modify, distribute, make, and sell the design or hardware based on that design.'
const COMM_DUMMY_DATA = [
  {mainHeader:"BECOME A PART OF OUR COMMUNITY",
  id:1,
   title: "Join our community of change makers",
   paragraph:"We are a community of makers, makerspaces, technologists, hacker hobbyists, educators, students, digital innovation hubs, government officials, start-up innovators, entrepreneurs, and researchers from Africa and around the world who seek to promote African innovation through open hardware principles. By becoming a member of our community, you will join an ecosystem of shared knowledge, ideas and resources to accelerate innovation in Africa.",
   btnTitle:'Become a member',
   reverse:"yes",
   commImage:`${GroupRight}`,
  btnLink:'https://docs.google.com/forms/d/1AsZitGfByBt-FjHPlV_D02Z-Jfgbs2rR4m75B-FgSZw/edit'},
  {mainHeader:"",
   title: "Let's work together!!",
   reverse:"no",
   id:2,
   paragraph:"Our community doors are always open to all who seek to partner with us on events and programmes and all who would want to work together on projects or initiatives in Africa and outside Africa. ",
   btnTitle:'Become a partner',
   commImage:`${ConnectL}`,
  btnLink:'https://docs.google.com/forms/d/e/1FAIpQLSehVJ5QxHOGxJsYUgBQlp5oNZUpA-ByKYtWpnbtq45CuCBGWQ/viewform?usp=pp_url'},
]

const Home = (props) => {
  const params = useParams();
  const footerSection = useRef()

  // const { targetId } = state || {};
const [appear, setAppear] = useState(false)
const trigger = useCallback(
  () => {
    if(params.targetId === 'to_news_letter'){
      window.scrollTo({
        top: footerSection.current.offsetTop,
        behavior:'smooth',
      })
     }
  },
  [params.targetId]
)



useEffect(() => {
  console.log(params.targetId)
  
  if(params.targetId){
    trigger()
   }
  setTimeout(() => {
    setAppear(false) 
  }, 25000);

}, [params,trigger])



  return (
    <Fragment>
      <CarouselHeader />
      {appear && <Modal state={appear} text={"The AfricaOSH Summit is a grassroots effort to bring together a community of researchers, educators, innovators, makers, makerspaces, and many more.This year's Summit applications are now open to everyone. The Summit is slated for September 29th to October 1st at MboaLab, Yaounde-Cameroon."} img={summitImg} alt={'summit'} />}
      <AboutSummary content={AboutContent} />
      <Guide/>
      <ParralaxHome/>
      <Mantra />  
      <Ideology />
      <Partners/>
     
      {COMM_DUMMY_DATA.map((index,key)=>{
       return(
       
       <ConnectWith key={index.id + key} mainHeader={index.mainHeader} reverse={index.reverse} title={index.title} paragraph={index.paragraph} btnLink={index.btnLink} btnTitle={index.btnTitle} commImage={index.commImage}/> 
 
       )
     })}
      <EventsView />
      <ProjectBanner/>
      <News />
      <SummitsSummary />
      
      <div id='to_news_letter'>
      <FooterOsh ref={footerSection}  margin={'0'} id={'to'}/>
      </div>

    </Fragment>
  );
};

export default Home;
