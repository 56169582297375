import { Grommet } from "grommet";
import React, { Fragment } from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram"
import { SocialLink, SocialLinksCon ,InputText } from "./Subscribe.style";
import { SubscribeContainer, SubscribeWrapper, ButtonSend } from "./Subscribe.style";
import { useRef ,useState} from 'react';


const theme = {

  global: {
    focus:{
      outline:{
        color:"#E0E0E0"
      }
    },
    input:{
      extend: `padding-right:'1rem';`
    },
  control:{
    border:{
      radius:"2px",
      
    }
  },
    colors: {
      border: "#E0E0E0",
      control: "#E0E0E0",
      brand: "hsl(214, 50%, 6%)",
      grey5: "#E0E0E0",
      text:"#fff",
      input:"#E0E0E0"
    
    },
    font: {
      family: "Roboto",
      size: "14px",
      height: "20px",
    },


  },
  textInput:{
    extend:`
      color:'#fff'
    `,
    container :{
      extend:`
      color:'#fff'
    `
    }

  }
};

const Subscribe = (props) => {
  const [enteredEmail, setEnteredEmail] = useState('')
  const [enteredEmailIsValid, setEnteredEmailIsValid] = useState(false);
  const [isTouched,setIsTouched]  = useState(false);
  // const [open , setOpen] = useState(false)
  const emailInputRef = useRef();




  // const handleToClose = (event, reason) => {
  //   if ("clickaway" === reason) return;
  //   setOpen(false);
  // };
  
  // const handleClickEvent = () => {
  //   setOpen(true);
  // };





  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value)
   }
 






  const submitHandler = (event) => {
    event.preventDefault();
  setIsTouched(true)
    const enteredValue = emailInputRef.current.value;
  if(!enteredValue.includes('@')){
    setEnteredEmailIsValid(false)
    return
  }
  if (enteredEmail.trim() === '') {
    setEnteredEmailIsValid(false);
    return;
  }
 
  setEnteredEmailIsValid(true);
    if (enteredValue.trim().length > 0) {
      props.onEnterInputEmail(enteredValue);
    }
   
    setEnteredEmail('');
    //not a good practice to manipulate the dom in react find a work around 
    emailInputRef.current.value="";
  };

  const invalidEmailInput = !enteredEmailIsValid && isTouched;
  
  return (
    <Fragment>
     
      <Grommet theme={theme} >
        <SubscribeContainer>
          <h5 style={{textAlign:'center'}} className="main-header whiteText" >Subscribe to our Newsletter</h5>
          <form onSubmit={submitHandler}>
          <SubscribeWrapper> 
          
            <div>
            <InputText onChange={emailChangeHandler} className={!enteredEmailIsValid ? 'error': ''} ref={emailInputRef} placeholder="Enter your email" />
            </div>

            <ButtonSend>{props.loading ? 'Sending...':'Send'}</ButtonSend>
            
          </SubscribeWrapper>
          {invalidEmailInput && <p className="validity"> Email invalid !</p>}
         
          </form>   
          <SubscribeWrapper>
            <SocialLinksCon>
              <SocialLink href="https://web.facebook.com/AfricaOSH">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://twitter.com/AfricaOSH?s=20&t=PmFoMUPHGC_SJzpkdEuC2A">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/africa-open-science-and-hardware-AfricaOSH/about/">
                <LinkedInIcon />
              </SocialLink>
              <SocialLink href="https://www.youtube.com/channel/UCnj0CBRImwyffNHwQ-BrKFA">
                <YouTubeIcon />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/africaosh/">
                <InstagramIcon />
              </SocialLink>
            </SocialLinksCon>
          </SubscribeWrapper>
        </SubscribeContainer>
      </Grommet>
    </Fragment>
  );
};

export default Subscribe;
