import { Fragment } from "react";
import { TemplateIcon } from "./Community.style";
import { Youtube } from "grommet-icons";
import { Facebook } from "grommet-icons";
import { Linkedin } from "grommet-icons";
import { Mail } from "grommet-icons";
import { Phone } from "grommet-icons";
import { PROJECT_DUMMY } from "./DATA";
import {
  CommunityPageCon,
  CommunityImage,
  CommuHeader,
  BannerImgCon,
  TextCommu,
} from "./Community.style";
import FooterOsh from "../../components/FooterOsh/FooterOsh";
import { useParams } from "react-router-dom";

const TemplateCommunity = (props) => {
  const routeParams = useParams();
  console.log(routeParams);
  const project = PROJECT_DUMMY.find(
    (project) => project.id === routeParams.comId
  );
  if (!project) {
    return <h3>Project not found!</h3>;
  }
  return (
    <Fragment>
      <h6
        style={{ color: "#424242", textAlign: "center" }}
        className="markdown"
      >
        {"Community Projects & Initiatives"}
      </h6>
      <CommunityPageCon>
        <h3 className="blackText main-header">{project.header}</h3>
        <CommunityImage>
          <img src={project.img} alt="Community" />
        </CommunityImage>
        <div style={{  width: "70%" }}>
          <CommuHeader>{project.header}</CommuHeader>
          <h6
        style={{ fontWeight:'bold',padding:'0',color: "#828282", textAlign: "left",margin:'0'}}
        className="markdown"
      >
        {'MAKER: '+project.maker}
      </h6>
        </div>
        <TextCommu>{project.desc}</TextCommu>
        {project.desc2 && <TextCommu>{project.desc2}</TextCommu>}
        {project.desc3 && <TextCommu>{project.desc3}</TextCommu>}
        {project.desc4 && <TextCommu>{project.desc4}</TextCommu>}
        <BannerImgCon>
          <div className="overlaycon">
            <TemplateIcon>
              <div className="tilewrapper">
                {project.youtube && (
                  <div className="tile">
                    <a
                      href={project.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Youtube size="" color="#fff" />
                    </a>
                  </div>
                )}
                {project.phone && (
                  <div className="tile">
                    <a
                      href={"tel:" + project.phone}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Phone size="" color="#fff" />
                    </a>
                  </div>
                )}
                {project.linkedin && (
                  <div className="tile">
                    <a
                      href={project.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin size="" color="#fff" />
                    </a>
                  </div>
                )}
                {project.facebook && (
                  <div className="tile">
                    <a
                      href={project.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook size="" color="#fff" />
                    </a>
                  </div>
                )}
                {project.mail && (
                  <div className="tile">
                    <a
                      href={"mailto:" + props.mail}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Mail size="" color="#fff" />
                    </a>
                  </div>
                )}
              </div>
            </TemplateIcon>
          </div>
          <img src={project.bannerImg} alt="shifter" />
        </BannerImgCon>
      </CommunityPageCon>
      <FooterOsh></FooterOsh>
    </Fragment>
  );
};

export default TemplateCommunity;
