import styled from "styled-components";
import bgImg from '../../assets/images/Intersection.png'

export const WhatContainer = styled.div `
display:flex;
flex-direction:column;
background-image: url(${bgImg});
background-size:cover;
background-repeat:repeat;
align-items:center;
justify-content:center;
padding:6rem;
background-color: #FFF;
margin: 2rem 0;
@media screen and (max-width:920px){
    padding:2rem;
}
`
export const WhatWrapper = styled.div `
display:flex;
flex-direction:column;
width:70%;
background-color:#f2f2f2;
padding:2.15rem;

h3{
    color:white;
}
ul{

    display:block;
    width:80%;
    text-align:left;
    margin-left:3rem;
    margin-top:1rem;
}
li{
    font-family: 'Karla', sans-serif;

    
}


@media screen and (max-width:920px){
    width:100%;
    padding:1rem;
}
`
export const Space = styled.div `
margin:1em;
`

export const Column = styled.div ` 
border:3px solid red;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
`