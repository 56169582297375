import { Grommet, Box } from "grommet";
import { Fragment } from "react";
import classes from "./AboutSummary.module.css";
import { AboutCon, AboutWrapper } from "./AboutSummary.styles";

const AboutSummary = (props) => {
  return (
    <Fragment>
      <Grommet>
        <Box background={"#131313"}>
          <AboutCon>
            <AboutWrapper>
              <h6 className="markdown">Open Science Hardware(OSH)</h6>
              <h1 className={`${classes.mainHeader} main-header`}>
                What is Open Science Hardware?{" "}
              </h1>
              <p className="Btext-paragraph">
              {props.content}
              <span><a style={{color:'white'}} href="http://openhardware.science" target="_blank" rel="noopener noreferrer"> (Gathering for Open Science Hardware)</a></span>
              </p>
              {/* <Link className={classes.subLink} to={"/about"}>
              Open source Hardware{" >>>"}
              </Link> */}
            
            </AboutWrapper>
          </AboutCon>
        </Box>
      </Grommet>
    </Fragment>
  );
};

export default AboutSummary;
