
import MantraItem from "./Mantra-Item";
import { Box, Grommet } from "grommet";


const DUMMY_MANTRA = [
  {
    name: "Dialogue",
    content:
      "We want to create a conversation among Africans and the rest of the world about Open Science and Open Science Hardware.",
    icon: "faComment",
  },
  {
    name: "Collaboration",
    content:
      " We collaborate with innovators, makers and makerspaces, government institutions,and everyone interested in open science to help accelerate innovations in Africa.",
    icon: "faHandBackFist",
  },
  {
    name: "Visibility",
    content:
      "We showcase new and existing OScH developed by African and other global south innovators and/or for the global South/majority world context.",
    icon: "faEye",
  },
];



const Mantra = (props) => {
    
  return (
    <Grommet>
      <Box  align="center"  direction="column"  alignSelf="center">
        <div className="container" style={{margin:"3em 1em"}} >   
        <Box justify="between" gap="xsmall"  direction="row-responsive"  >
          {DUMMY_MANTRA.map((i, key) => {
           
            return (
              <MantraItem
                key={key}
                symbol={i.icon}
                mantraText={i.content}
                mantraHeader={i.name}
              />
            );
          })}
          </Box>
        </div>
      </Box>
    </Grommet>
  );
};

export default Mantra;
