import styled from 'styled-components';

export const MainModalCon = styled.div ` 
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


`

export const ModalCon = styled.div `
display:flex;
flex-direction:column;
justify-content:center;
align-items:flex-end;
  width:80%;
  margin:1rem 2rem;
`;

export const ModalWrapper = styled.div ` 

display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

`

export const ImgCon = styled.div ` 
width:50%;
@media screen and (max-width:679px){
  width:80%;
}
`

export const ModalImg = styled.img ` 
width:100%;
object-fit:contain;
display:block;
/* @media screen and (max-width:1200px){
max-height:10vh;
} */
`
export const MoadlCon = styled.div ` 
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


`
export const ModalLink = styled.button ` 
padding: .5rem 1rem;
align-self:center;
margin:1rem; 
border:none;
outline:none;
border-radius:3px;
background-color:black;

a {
    text-transform:uppercase;
    color:white;
    text-decoration:none;
}
`