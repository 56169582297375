import { Fragment,useState,useEffect } from "react";
import { GuideCon, GuideWrapper, GuideContent, GuideBtn } from "./Guide.style";
import { ref, getDownloadURL  } from "firebase/storage";
import storage from "../../libs/Firebase";
const Guide = (props) => {
    const [file, setFile] = useState();
    let link;
    useEffect(() => {
      
        const gsReference = ref(storage, 'guide.pdf')
        
           getDownloadURL(gsReference).then(res => {
            link = res
           setFile(link)
         
          })
    }, [])
    


   

        
  return (
    <Fragment>
      <GuideCon>
        <GuideWrapper>
          <GuideContent>
            <h5 className="markdown">New to AfricaOSH ?</h5>
            <h3 className="blackText main-header">
              Checkout our community welcome guide {'>>>'}
            </h3>
          </GuideContent>
          <GuideBtn>
            <button>
            <a href={file}>{file ? 'Download' : 'Get Report'}</a>
            </button>
          </GuideBtn>
        </GuideWrapper>
      </GuideCon>
    </Fragment>
  );
};

export default Guide;
