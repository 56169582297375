import styled from "styled-components";

export const MboaPageCon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const MboaPageWrapper = styled.div `
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: #828282;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-top: 2.5rem;
    text-align: left;
  }
  h2{
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    color: #424242;
    margin-bottom:1rem;
  }
`;