import { Fragment } from "react";
import { GridPartnerCon , GridTextHeader} from "./GridPartners.style";
import GIGI_IMG from "../../assets/images/gig.png";
import ROG_IMG from "../../assets/images/rog.png";
import PLOS_IMG from "../../assets/images/plos.png";
import OVERLEAF_IMG from "../../assets/images/overleaf.png";
import GOSH_IMG from "../../assets/images/gosh.png";
// import PSL_IMG from "../../assets/images/0.png";
import EXPL_IMG from "../../assets/images/exploratory.png";
import BONGO_IMG from "../../assets/images/BongoTech.png";
import GLOBAL_IMG from "../../assets/images/Global.png";
import APSOHA_IMG from "../../assets/images/apsoha.png";
import KH_IMG from "../../assets/images/KH.png";
import AIR_IMG from "../../assets/images/openAir.png";
import GridParnterList from "./GridParnterList";
import AFRIC from "../../assets/images/afric.png"

const GridParnters = (props) => {
  const PartnersArray = [
    { img: GOSH_IMG, name: "GOSH", Link: "openhardware.science/" },
    { img: GLOBAL_IMG, name: "GLOBAL", Link: "https://glabghana.wordpress.com/" },
    { img: APSOHA_IMG, name: "APSOHA", Link: "https://www.projetsoha.org/" },
    { img: EXPL_IMG, name: "Exploratroy", Link: "https://the-exploratory.org/" },
    { img: OVERLEAF_IMG, name: "Overleaf", Link: "https://www.overleaf.com/" },
    { img: GIGI_IMG, name: "Gigi", Link: "https://globalinnovationgathering.org/" },
    { img: AIR_IMG, name: "Open Air", Link: "https://openair.africa/" },
    { img: PLOS_IMG, name: "PLOS", Link: "https://plos.org/" },
    // { img: PSL_IMG, name: "PSL", Link: "http://prac-sciencelab.com/" },
    { img:AFRIC,name:"Afric",Link:"https://info.africarxiv.org/" },
    { img: BONGO_IMG, name: "BONGO", Link: " https://btech.co.tz" },
    { img: ROG_IMG, name: "Rog", Link: "https://openculture.agency/" },
    { img: KH_IMG, name: "Kumasi Hive", Link: "https://kumasihive.com/" },

  ];

  return (
    <Fragment>
      <GridTextHeader>
       {/* <h5 className="markdown">
          organisations we collaborate with
        </h5> */}
       
          <h3 style={{textAlign:"center"}} className="main-header blackText">Members & Partners</h3>
        
        </GridTextHeader>
      <GridPartnerCon>
     
        {PartnersArray.map((index, key) => {
          return (
            <GridParnterList
              key={key}
              image={index.img}
              name={index.name}
              href={index.Link}
            />
          );
        })}
      </GridPartnerCon>
    </Fragment>
  );
};

export default GridParnters;
