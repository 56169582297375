import { Fragment } from "react";
import styled from "styled-components";
import {
  NewsImg,
  NewsImgCon,
  NewsSection,
  NewsWrapper,
  BlogCon,
  NewsCon,
  NewsHeaderText,
  NewsHeaderTextCon,
  NewsDateCon,
  NewsDate,
} from "./News.style";
import { Link } from "react-router-dom";
const StoryLink = styled(Link)`
    color: #424242;
    text-decoration: none;
  `;
const NewsList = (props) => {
  

  return (
    <Fragment>
      <BlogCon>
        <NewsCon>
          <NewsWrapper>
            <StoryLink to={`/news/${props.id}`}>
              <NewsSection>
                <NewsImgCon>
                  <NewsImg src={props.imageLink} alt="newsImage" />
                </NewsImgCon>

                <NewsHeaderTextCon>
                  <NewsHeaderText>
                    {props.title.slice(0, 60).concat("...")}
                  </NewsHeaderText>
                </NewsHeaderTextCon>
                <NewsDateCon>
                  <NewsDate>{props.date}</NewsDate>
                </NewsDateCon>
              </NewsSection>
            </StoryLink>
          </NewsWrapper>
        </NewsCon>
      </BlogCon>
    </Fragment>
  );
};

export default NewsList;
