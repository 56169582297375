import { Fragment ,useEffect,useState} from "react";
// import required modules
import { Pagination, Navigation,Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  Restrict,
  PartnerContainer,
  PartnerWrapper,
  PartnerImg,
  PartnerImgCon,
} from "../Partners/Partners.style";
import GIGI_IMG from "../../assets/images/gig.png";
import ROG_IMG from "../../assets/images/rog.png";
import PLOS_IMG from "../../assets/images/plos.png";
import OVERLEAF_IMG from "../../assets/images/overleaf.png";
import GOSH_IMG from "../../assets/images/gosh.png";
import PSL_IMG from "../../assets/images/0.png";
import EXPL_IMG from "../../assets/images/exploratory.png";
import BONGO_IMG from "../../assets/images/BongoTech.png";
import GLOBAL_IMG from "../../assets/images/Global.png";
import KH_IMG from "../../assets/images/KH.png";
import APSOHA_IMG from "../../assets/images/apsoha.png";
import AFRIC from "../../assets/images/afric.png"
const Partners = (props) => {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => { 

    updateDimensions();
  

    window.addEventListener("resize", updateDimensions);  
       return () => 
      window.removeEventListener("resize",updateDimensions);  
    
       
     }, [])

const updateDimensions = () => {
  const width = window.innerWidth
  setWindowWidth(width);
}

  const PartnersArray = [
    GOSH_IMG,
    GLOBAL_IMG,
    BONGO_IMG,
    EXPL_IMG,
    OVERLEAF_IMG,
    GIGI_IMG,
    ROG_IMG,
    PLOS_IMG,
    PSL_IMG,
    KH_IMG,
    APSOHA_IMG,
    AFRIC
  ];

  

  return (
    <Fragment>
      <PartnerContainer>
        <h5 className="markdown">
          organisations 
        </h5>
        <PartnerWrapper>
          <h3 className="main-header blackText">Members & Partners</h3>
        </PartnerWrapper>

        <Restrict >
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            spaceBetween={20}
            slidesPerView={width > 1024 ? 4 :2}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation,Autoplay]}
            className="mySwiper"
          
          >
           {PartnersArray.map((index,key) => (
             <SwiperSlide  key={key+1}>
               <PartnerImgCon key={key+2}>
               <PartnerImg    src={index} key={key+3}></PartnerImg>
               </PartnerImgCon>
             </SwiperSlide>
           ))}
   
          </Swiper>
        </Restrict>
      </PartnerContainer>
    </Fragment>
  );
};

export default Partners;
