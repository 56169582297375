import { Fragment } from "react";
import { ProjectContainer, ProjectWrapper,BannerContainer,MainConWrapper,ProjectDetail,DetailWrapper } from "./ProjectBanner.styled";
import makeLogo from "../../assets/images/makeLogo.png";
import styled from 'styled-components';
import { Link } from "react-router-dom";
const StyledLink = styled(Link)`
  color: #ffffff4e;
  text-decoration: none;
`;
const ProjectBanner = (props) => {
  return (
    <Fragment>
      <MainConWrapper>
  <BannerContainer>
  <h5 className="markdown">EU-Funded Projects</h5>
  </BannerContainer>
  <BannerContainer>
  <h3 className="blackText main-header">African European Maker Innovation Ecosystem </h3>
  </BannerContainer>
      <ProjectContainer>
        <ProjectWrapper>
          <DetailWrapper>
            <ProjectDetail>
              {/* <h3>Projects</h3> */}
              <img className="pojectLogo" src={makeLogo} alt="makeLogo" />
              <StyledLink to="/project" className="btnMore">Read More</StyledLink>
            </ProjectDetail>
          </DetailWrapper>
        </ProjectWrapper>
      </ProjectContainer>
      </MainConWrapper>
    </Fragment>
  );
};

export default ProjectBanner; 
