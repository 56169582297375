import styled from "styled-components";

export const AboutTopHeaderCon = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 979px) {
    flex-direction: column;
  }
`;
export const FirstCon = styled.div `
  width: 50%;
  display:flex;
  img{
    width:100%;
    display:block;
    object-fit:cover;
  }
  @media screen and (max-width: 979px) {
    width: 100%;
  }
`;
export const SecondCon = styled.div `
  width: 50%;


  @media screen and (max-width: 979px) {
    width: 100%;
  }
  h3 {
    padding-top:2rem;
    padding-left:2rem;
    font-size: 1.9rem;
    letter-spacing: normal;
    font-weight: 700;
    padding-bottom: 2rem;
    line-height: normal;
  }
  p {
     padding-left:2rem;
    text-align: left;
    margin-right: 15%;
    font-size: 1.25rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.25rem;
    color: #828282;
    margin-bottom: 1.25rem;
 
  }
`;