import styled from 'styled-components';
import gimage from '../../assets/images/gears.png';
import limage from '../../assets/images/flask.png'

export const TanzaniaCon = styled.div `
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
margin-top:2rem;
margin-bottom:2rem;
background-color:#F5F5F5; 
`;

export const TanzanianWrapper = styled.div ` 
width:70%;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

h2{
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    color: #424242;
}

p{
    color: #828282;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: center;
    margin-top: 1.5rem;
    padding-left:2rem;
    padding-right:2rem;
}
`
export const TabletContainer = styled.div ` 
display:flex;
flex-direction:column;
width:70%;
margin-top:1rem;
background-image:url(${gimage});
background-repeat:no-repeat;
background-position: right top;
position:relative;
z-index:2;

@media screen and (max-width:967px){
    background-size:2.5rem;
}
`

export const TabletImgVideo = styled.div ` 
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
position: relative;
margin: 6rem;
@media screen and (max-width:967px){
    margin:.2rem;
}

img{
    display:block;
    object-fit:contain;
    width:100%;

    @media screen and (max-width:967px){
    
}
}

iframe{
    position:absolute;
    width:93.9%;
    height:90%;
    border-radius:5px;
}
`
export const Flask = styled.img ` 
position:absolute;
width:8rem;
padding-top:22rem;
left:33rem;
z-index: 1;
`