
import { Fragment ,useState } from "react";
import {Box,Layer, Text} from "grommet"
import { Close } from "grommet-icons";
import {ModalLink,MainModalCon,ModalWrapper,ModalImg,ModalCon,ImgCon} from "./Modal.style"

const Modal = (props) => {

    const [show, setShow] = useState(props.state)
  return (
    <Fragment>
      <Box>
        {/* <Button label="show" onClick={() => setShow(props.state)} /> */}
        {show && (
          <Layer
            onEsc={() => setShow(props.state)}
            onClickOutside={() => setShow(props.state)}

          > <MainModalCon>
            <ModalCon>
            <Close color={"black"} onClick={() => setShow(() => !props.state)} />
            <ModalWrapper>
            <ImgCon>
            
            <ModalImg src={props.img} alt={props.alt}/>
            </ImgCon>
            <ModalCon>
<Text>{props.text}</Text>

<ModalLink><a target="_blnak" href="https://docs.google.com/forms/d/e/1FAIpQLSdOwXEONbacMeYX5p1BEpWT20uyQZuByTiRBkLfgGPDXKjUhA/viewform">apply now</a></ModalLink>
            </ModalCon>
            </ModalWrapper>
            </ModalCon>
            </MainModalCon>

          </Layer>
        )}
      </Box>
    </Fragment>
  );
};

export default Modal;
