import styled from "styled-components";

export const AboutHeaderCon = styled.div `
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;

`
export const AboutHeaderWrapper = styled.div `
display:flex;
justify-content:space-between;
align-items:flex-start;
width:70%;
padding: 1rem;
//this guy made me fuck up here
margin:2rem;


@media screen and (max-width:920px){
flex-direction:column;
width:100%;
}
`
export const ContentInfo = styled.div `
width:50%;
display:flex;
flex-direction:column;
padding:1rem;

@media screen and (max-width:920px) {
    width:100%;
}
`
export const AboutHeaderConImg = styled.div `



`
export const AboutHeaderImg = styled.img `
width:100%;
height:100%;
object-fit:contain;
padding:.2em;
`
export const MainAboutInfoCon = styled.div `
display:flex;
text-align:center;
flex-direction:column;
align-items:center;
margin:2rem 0;


`
export const ShortDesc = styled.h5 `
    color: #5b6278;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.25px;
    line-height: 2.1rem;
    font-weight: lighter;
    margin-top: 1rem;
`