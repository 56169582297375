import styled from 'styled-components';
import bgImg from "../../assets/images/adinkra.png"
export const MainFooterCon = styled.div `
display:flex;
background-color:#3B3B3B;
flex-direction:column;
justify-content:center;
align-items:center;
margin-top:${props => props.top ? props.top : 2}rem;
padding-top:6rem;
padding-bottom:2rem;
background-image:url(${bgImg});
background-repeat:repeat-x;
background-position:center top;
background-size:63em;

`
export const MainFooterWrapper = styled.div `
display:flex;
justify-content:space-between;
align-items:flex-start;
width:calc(82% - .5rem);


@media screen and (max-width:920px){
    flex-direction:column;
    width:100%;
     
    align-items:center;
}

`
export const FooterLogoCon = styled.div `
 border:4px solid red;
min-width:12rem;
@media screen and (max-width:679px){
    margin-bottom:3rem;
}
`
export const FooterLogoImg = styled.img `
width:100%;
height:100%;
object-fit:contain;

`
export const FooterSpan = styled.a `
color: #E0E0E0;
text-decoration:none;

`
export const FooterHeaderText = styled.div `
font-style: normal;
font-weight: 700;
color: white;
font-size:24px;
/* padding-bottom:.4rem; */
`


export const ColumnCon = styled.div `
text-align:left;
display:flex;
flex-direction:column;
align-items:baseline;   
justify-content:flex-start;
line-height:2.3rem;

/* width:calc((100% * 4) + 8rem ); */

a {
    text-decoration:none;
    color:#EDEDED;
}
@media screen and (max-width:920px){
    padding:1rem;
   
  
   

}
@media screen and (max-width:920px){
    margin:0 auto;
    min-width:18rem;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
`

export const ContactInfoCon = styled.div `
display:flex;
padding-bottom: .5rem;
align-items:center;
`
export const LinkSpan = styled.a `
margin-left: .5rem;
color:#EDEDED;
display:inline-block;
`