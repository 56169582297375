import { Fragment } from "react";
import VissionImg from "../../assets/images/future.jpg";

import { Text } from "grommet";

import {
  AboutHeaderCon,
  AboutHeaderWrapper,
} from "../AboutHeader/AboutHeader.style";
import { ValueContent } from "./Value.style";
import MissionImg from "../../assets/images/vision.jpg";
const Values = (props) => {
  return (
    <Fragment>
      <h5 className="markdown">Our Core Values</h5>
      <AboutHeaderCon>
        <AboutHeaderWrapper>
          <ValueContent>
            <div className="main-header blackText">Vision</div>

            <Text
              textAlign="justify"
              margin={{ bottom: "3rem" }}
              color={"#828282"}
              style={{ fontSize: "1.25rem" }}
            >
              AfricaOSH’s vision is an Africa where hardware designs,
              blueprints, etc are accessible to everyone. To create an ecosystem
              of shared knowledge, shared ideas and shared resources.
            </Text>
            <img id="visionImg" src={MissionImg} alt="Vision" />
          </ValueContent>
          <ValueContent>
            <div className="main-header blackText">Mission</div>
            <img src={VissionImg} alt="Mission" />
            <Text
              textAlign="justify"
              margin={{ top: "2rem", bottom: "2rem" }}
              color={"#828282"}
              style={{ fontSize: "1.25rem" }}
            >
              AfricaOSH's goal is to create a set of actions and conversations
              around open science hardware (OSH) among African actors and the
              international community, in order to adopt OSH principles and
              practices appropriate to our African context.
            </Text>
          </ValueContent>
        </AboutHeaderWrapper>
      </AboutHeaderCon>
    </Fragment>
  );
};

export default Values;
