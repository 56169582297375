import AchievementBox  from "./AchievementBox";
import { Fragment } from "react";
import { Parallax } from "react-parallax";
import { ParallaxCon } from "./ParralaxHome.styles";
import React from "react";
import   ahappy  from "../../assets/images/happy.png"
// const pImg =
// "https://images.unsplash.com/photo-1521264820992-b69704bc52a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fHplYnJhfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60";

const ACHIEVEMENT_DATA = [
    {   id:'1',
        icon:'faSquareRss',
        desc:'Community members',
        number:'350',
        duration:'2',
        plus:'+'
    },
    {   
      id:'2',
        icon:'faFlag',
        desc:'Country',
        number:'50',
        duration:'2',
        plus:''
    },
  
    { 
      id:'4',
        icon:'faEarthAfrica',
        desc:'African countries',
        number:'36',
        duration:'2',
        plus:''
    },
    { 
      id:'3',
        icon:'faCalendarCheck',
        desc:'Summits hosted',
        number:'3',
        duration:'2',
        plus:''
    },
]
const GO_DATA = [
    {   id:'42',
        icon:'faHandshake',
        desc:'Partners',
        number:'10+',
        duration:'2',
        plus:'+'
    },
    {   
      id:'21',
        icon:'faEarthEurope',
        desc:'Non African Countries',
        number:'14',
        duration:'2',
        plus:'+'
    },
    
]



const ParralaxHome = (props) => {


  return (
    <Fragment>
      <Parallax blur={{ min: -15, max: 15 }} strength={500} bgImage={ahappy}>
        <ParallaxCon>
          <div className="pcontainer">
              <div className="wrapper" >
                {ACHIEVEMENT_DATA.map((i) => {
                  return   <AchievementBox duration={i.duration} key={i.id} icon={i.icon} title={i.desc} num={i.number} plus={i.plus} />
                })}
                 {/* {GO_DATA.map((i) => {
                  return   <AchievementBox duration={i.duration} key={i.id} icon={i.icon} title={i.desc} num={i.number} plus={i.plus} />
                })}        */}
            </div>
          </div>
        </ParallaxCon>
      </Parallax>
    </Fragment>
  );
};

export default ParralaxHome;
