import { Text } from "grommet";
import { Fragment } from "react";
import { TextContainer } from "./InfoSummit.style";
const InfoSummit = (props) => {
  return (
    <Fragment>
      <TextContainer>
        <Text style={{fontSize:'1.25rem'}}>
          The AfricaOSH Summit is a gathering for everyone interested in Open
          Science Hardware. AfricaOSH has already hosted 2 successful summits.
          The first was held in Ghana, at Kumasi Hive (2018) and the other at
          STICLab(currently known as Bongo Tech and Research Labs), in Tanzania (2019). Participants of our summits include but
          are not limited to makers, hackers, practitioners and researchers in
          science, technology and engineering, government officials, private
          sector players and civil society across the African continent, the
          Global South and the world.
        </Text>
      </TextContainer>
    </Fragment>
  );
};

export default InfoSummit;
