import { Fragment } from 'react';
import './App.css';
import MainNav from './components/MainNav/MainNav';
import {Routes,Route} from 'react-router-dom';
import  Home  from './pages/Home/Home';
import  About  from './pages/About/About';
import Summits from './pages/Summits/Summits'
import Contact from './pages/Contact/Contact'
import Events from './pages/Events/Event'
import NewsDetailPage from './pages/News/NewsDetailPage';
import Blog from './pages/News/Blog';
import ProjectDetail from './pages/ProjectDetail/ProjectDetail'
import SummitPage from './pages/SummitPage/SummitPage';
import MainSummitPage from './components/MainSummitPage/MainSummitPage';
import MboaLabPage from './pages/MboaLabPage/MboaLabPage';
import CommunityPage from './pages/Community/Community';
import TemplateCommunity from './pages/Community/Templatecommunity';

function App() {
  return (
   <Fragment>
     <MainNav/>
     <main>
       <Routes>
         <Route  path='/#' element={<Home/>}/>
         <Route path='/subscribe/:targetId' element={<Home/>}/>
         <Route path='/communitypage' element={<CommunityPage/>}/>
         <Route path='/communitypage/:comId' element={<TemplateCommunity/>}/>
         <Route path='/about' element={<About/>}/>
         <Route path='/events' element={<Events/>}/>
         <Route path='/contactus' element={<Contact/>} /> 
         <Route path='/summits' element={<Summits/>}/>
         <Route path='/summits/:summitsId' element={<SummitPage/>}/>
         <Route path='/summitpage' element={<MainSummitPage/>}/>
         <Route path='/project' element={<ProjectDetail/>}/>
         <Route path='/news' element={<Blog/>}/>
         <Route path='/MboaLab' element={<MboaLabPage/>}/>
         <Route path='/news/:newsId' element={<NewsDetailPage/>}/> 
         <Route path='*' element={<Home/>}/>
       </Routes>  
     </main>
   </Fragment>
     
   
   )
}

export default App;
