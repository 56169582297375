import {
  EventListImg,
  EventListCon,
  EventListWrapper,
  EventListDescCon,
  EventListImgCon,
} from "./EventHeader.style";
import { Fragment } from "react";

const EventList = (props) => {
  return (
    <Fragment>
      <EventListCon>
        <EventListWrapper>
          <EventListImgCon>
            {props.video ? (
              <iframe
                width="560"
                height="315"
                src={props.videoLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <EventListImg alt="Event Image" src={props.image} />
            )}
          </EventListImgCon>
          <EventListDescCon>
            <div className="categoryBox">
              <p>{props.category}</p>
            </div>
            <div className="themeBox">{props.theme}</div>
            <div className="descBox">{props.description}</div>
            <div className="authorBox">
              {props.speakers ? "Speakers: " + props.speakers : props.host}
            </div>
            <div className="locationBox" >
            { props.link && <span style={{paddingRight:'2rem'}}>
             <button className="btnLink"> <a style={{color:'white'}} href={props.link}>Register here !</a>
             </button>
            </span> }
              <span>Date: </span>
              {props.date}
              
            </div>
     
          </EventListDescCon>
        </EventListWrapper>
      </EventListCon>
    </Fragment>
  );
};

export default EventList;
