import { Fragment } from "react";
import { Box, Carousel, Grommet, Image } from "grommet";
import { OuterShell } from "./Carousel.style";
import { CarouselCon } from "./Carousel.style";
import styled from "styled-components";
import wdude from "../../assets/images/wdude.jpg";
import elec from "../../assets/images/elec.jpg";
import trios from "../../assets/images/trios.jpg";
const theme = {
  global: {
    colors: {
      control: "#E0E0E0",
      brand: "hsl(214, 50%, 6%)",
      grey5: "#E0E0E0",
    },
    font: {
      family: "Roboto",
      size: "14px",
      height: "20px",
    },
  },
  image: {
    extend: { "background-color": "#E0E0E0", width: "100%" },
  },
  menu: {
    extend: `padding-right :3px`,
    icons: {
      color: "#E0E0E0",
    },
  },
};

const OshImage = styled(Image)`
  width: 100%;
`;

const CarouselHeader = (props) => {
  return (
    <Fragment>
      <Grommet theme={theme}>
        <OuterShell>
          <Box fill overflow="hidden">
            <Carousel wrap={true} fill play={5000}>
              <Box fill>
             
                <CarouselCon>
                  <h2>Innovate Africa</h2>
                  <p>
                    We want to inspire African Makers to create and build their
                    ideas.
                  </p>
                </CarouselCon>
                <Image fit="fill" src={trios} />
              </Box>
              <Box fill>
                {" "}
                <CarouselCon>
                  <h2>Let’s talk Open Science</h2>
                  <p>
                    Creating a conversation among Africans about Open Science
                    Hardware.
                  </p>
                </CarouselCon>
                <Image fit="fill" src={wdude} height={"20rem"} />
              </Box>
              <Box fill>
                {" "}
                <CarouselCon>
                  <h2>Show the world your innovations</h2>
                  <p>
                    Creating & providing a platform for Africans to showcase
                    their creations and innovations.
                  </p>
                </CarouselCon>
                <OshImage fit="fill" src={elec} />
              </Box>
            </Carousel>
          </Box>
        </OuterShell>
      </Grommet>
    </Fragment>
  );
};

export default CarouselHeader;
