import { TextInput, TextArea, Grommet } from "grommet";
import React,{ useState, Fragment, useRef,useEffect } from "react";
import {
  InputFullWrapper,
  ContactFormCon,
  InputWrapper,
  InputRow,
  ButtonSubmit,
  HeaderContactText,
} from "./ContactForm.style";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

const ContactForm = (props) => {
  const [empty, setEmpty] = useState(false);
  const emailInputRef = useRef();
  const nameInputRef = useRef();
  const subjectInputRef = useRef();
  const messageInputRef = useRef();
  const [open , setOpen] = useState(false)
  
 const handleToClose = ()=> {
   setOpen(false)
 }
  
  useEffect(() => {
    if(props.sent === true) {
      setOpen(true)
    }else {
      setOpen(false)
    }
  }, [props.sent])



  const onSubmitHandler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredName = nameInputRef.current.value;
    const enteredSubject = subjectInputRef.current.value;
    const enteredMessage = messageInputRef.current.value;

    const formValue = {
      email: enteredEmail.trim(),
      name: enteredName.trim(),
      message: enteredMessage.trim(),
      subject: enteredSubject.trim(),
    };
    if (!enteredEmail.includes("@")) {
      setEmpty(true);
    }
    if (!enteredMessage.trim === " ") {
      setEmpty(true);
    }

    if (enteredEmail.trim().includes("@") & enteredMessage.trim() !== ' ') {
    
      props.onEnteredFormValue(formValue);
      console.log(formValue)
      emailInputRef.current.value = " ";
      nameInputRef.current.value = " ";
      subjectInputRef.current.value = " ";
      messageInputRef.current.value = " ";
    }
  };

  return (
    <Fragment>
      <Grommet>
        <ContactFormCon onSubmit={onSubmitHandler}>
          <HeaderContactText>Enquiries</HeaderContactText>

          <InputRow>
            <InputWrapper style={{ paddingRight: ".5em" }}>
              <TextInput ref={nameInputRef} placeholder="Full Name" />
            </InputWrapper>
            <InputWrapper>
              <TextInput ref={emailInputRef} placeholder="Email" />
            </InputWrapper>
          </InputRow>
          {empty && (
            <small style={{ color: "red" }}>Please enter your email</small>
          )}
          <InputRow>
            <InputFullWrapper>
              <TextInput
                id="subject"
                ref={subjectInputRef}
                placeholder="Subject"
              />
            </InputFullWrapper>
          </InputRow>
          <InputRow>
            <TextArea
              ref={messageInputRef}
              fill
              placeholder="type your message here"
            />
          </InputRow>
          {empty && <small style={{ color: "red" }}>Enter your message</small>}
          <ButtonSubmit
            disabled={empty}
            style={empty ? { background: "grey" } : { background: "black" }}
            type="submit"
          >
            {props.isLoading ? "Sending..." : "submit"}
          </ButtonSubmit>
          {props.error && <small style={{ color: "red" }}>{props.error}</small>}
        </ContactFormCon>
      </Grommet>
      {props.sent && 
           <Snackbar
           anchorOrigin={{
             horizontal: "left",
             vertical: "bottom",
           }}
           open={open}
           autoHideDuration={1000}
           message={props.error ? props.error.message : 'Email Sent'}
           onClose={handleToClose}
           action={
             <React.Fragment>
               <IconButton
                 size="small"
                 aria-label="close"
                 color="inherit"
            
               >
                 <CloseIcon fontSize="small" />
               </IconButton>
             </React.Fragment>
           }
         />
      
      
      
      }
    
    </Fragment>
  );
};

export default ContactForm;
