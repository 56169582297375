import React, { useState } from "react";
import {
  AboutHeaderCon,
  MainAboutInfoCon,
  ShortDesc,
} from "../AboutHeader/AboutHeader.style";
import { Fragment } from "react";
import { MainSummitWrapper } from "../SummitsHeader/SummitsHeader.style";
import ListSummit from "../SummitsSummary/ListSummit";
import { ref, getDownloadURL  } from "firebase/storage";
import storage from "../../libs/Firebase";
import InfoSummit from "../InfoSummit/InfoSummit";
import Frank from "../../assets/images/2022.jpg"

const SUMMMITDUMMY_DATA = [
    { 
        id:1,
        ViewImage:
          `${Frank}`,
        date: "September 29th to October 1st",
        hostVenue: "MboaLab, Yaoundé, Cameroon",
        summitHeader: "2022 AfricaOSH Summit",
        link:'/summitPage',
        read:true,
        downloadable:false,
        summitDesc:
          "Growing the Do-It-Yourself & Do-It-Together (DIY/DIT) Culture for Community Transformation : a focus on Open Health",
      },
    { 
        id:2,
        ViewImage:
          "https://drive.google.com/uc?export=view&id=1OP1QQ3D7w44Yp1LNFCYp7G6Icyhs9uuq",
        date: "Date: Thursday, 11th - Saturday, 13th April, 2019",
        hostVenue: "STICLab (Bongo Tech and Research Labs), Dar es Salam - Tanzania",
        summitHeader: "2019 AfricaOSH Summit",
        dlink:'gs://aosh-db.appspot.com/2019report.pdf',
        downloadable:true,
        link:'',
        read:false,
        summitDesc:
          "A 3 day program mainly based on the sharing of ideas.It is rounded up by discussions on the future of AfricaOSH as a movement for change on the continent.",
      },

  { id:3,
    ViewImage:
      "https://drive.google.com/uc?export=view&id=11Rrt36D8UIalGQaEKWdh13k0xubuAkVv",
    date: "13th - Sunday, 15th April, 2018.",
    hostVenue: "Kumasi Hive,Ghana",
    downloadable:true,
    link:'',
    read:false,
    dlink:'gs://aosh-db.appspot.com/2018report.pdf',
    summitDesc:
      "Africa Open Science Hardware  is a grassroots effort to bring together industry professionals and  start-up innovators from around the world",
    summitHeader: "2018 AfricaOSH Summit",
  }

];

const SummitsHeader = (props) => {
const [file, setFile] = useState(null);



const onDownload =(event) => {  

const dLink = event.target.id;
if(event.target.id === ""){
  return;
}


const gsReference = ref(storage, dLink)

   getDownloadURL(gsReference).then(res => {
    const link = res
   setFile(link)
  
  })

}




  return (
    <Fragment>
      
      <AboutHeaderCon>
        <MainAboutInfoCon>
          <ShortDesc>AfricaOSH Summits</ShortDesc>
          <h3 className="blackText main-header">Africa Open Science Hardware Annual Summits</h3>
        </MainAboutInfoCon>
        <InfoSummit/>
        <MainSummitWrapper>
         
          {SUMMMITDUMMY_DATA.map( index => {
            return (
           
                <ListSummit
                  pdf={file}
                  summitHeader={index.summitHeader}
                  key={index.id}
                  date={index.date}
                  hostVenue={index.hostVenue}
                  ViewImage={index.ViewImage}
                  summitDesc={index.summitDesc}
                  downloadable={index.downloadable}
                  onClick={onDownload}
                  dlink={index.dlink}
                  Link={index.link}
                  read={index.read}
                />
         
           
            );
          })}
        </MainSummitWrapper>
      </AboutHeaderCon>
    </Fragment>
  );
};

export default SummitsHeader;
