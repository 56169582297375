import styled from "styled-components";

export const SMPstyleCon = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;
export const SMPstyleWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-bottom: 2rem;
  @media screen and (max-width: 967px) {
    flex-direction: column;
  }

  .ImageCon {
    width: 40%;
    @media screen and (max-width: 967px) {
      width: 100%;
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    display: block;
  }
  .descCon {
    width: 50%;
    text-align: left;
    @media screen and (max-width: 967px) {
      width: 100%;
    }
  }
  h2 {
    color: #424242;
    font-size: 1.9rem;
    letter-spacing: normal;
    font-weight: 700;

    margin-bottom: 1rem;
  }
  p {
    color: #828282;
    font-size: 1.25rem;
    font-weight: 400;
  }
`;