import styled from "styled-components";

export const ContactFormCon = styled.form `
display:flex;
flex-direction:column;
justify-content:space-between;
height:19.15rem;


`
export const InputRow = styled.div `
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
color:#424242;
width:100%;
`

export const InputWrapper = styled.div `
width:50%;
padding-bottom: 1rem;
color:#424242;
`

export const InputText = styled.input `
border:2px solid #E0E0E0;
font-size:1.25rem;
padding:.5em;
color:white;
`
export const InputFullWrapper = styled.div `
width:100%;
color:#424242;
padding-bottom: 1rem;
`
export const HeaderContactText = styled.h3 `

    font-size: 1.3rem;
    letter-spacing: normal;
    line-height: normal;
    font-weight: 700;
    padding-bottom: 2rem;
    color:#424242;
`

export const ButtonSubmit = styled.button `
background-color:#1f1f1f;
color:white;
width: 45%;
font-weight:normal;
outline:none;
letter-spacing:.2rem;
border-radius:2px;
border:none;
text-transform:capitalize;
margin-top:1em;
padding: .6em ;
`