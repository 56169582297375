import { Fragment } from "react";
import { SMPHeaderCon,SMPHeaderWrapper,SMPHInfoCon,SMPHImgCon,SMPMainImgCon } from "./SMPHeader.style";

const SMPHeader = (props) => {
    return ( 
        <Fragment>
            <SMPHeaderCon>
                <SMPHeaderWrapper>
                    <SMPHInfoCon>
                        <h5 style={{marginTop:'1rem'}} className="markdown">More on Africaosh summit</h5>
                            <h3 className="oshTitle">AFRICAOSH SUMMIT 2022 | September 29 - October 1, Cameroon.</h3>
                            <p className="sumPara">Spend 3 days with Scientists, Technologists, makers, Policymakers, Professors, Researchers, and Students from Africa and around the world to help shape the future of Open Science in Africa!
Join workshops, co-creation sessions, plenary sessions, presentations, unconferences. talks by both African & international community members. Share your experiences with everyone else as we promote open science in Africa while exploring the beautiful country of Cameroon.
.</p>
                    </SMPHInfoCon>
                    <SMPMainImgCon>
                    <SMPHImgCon/>
                    </SMPMainImgCon>
                </SMPHeaderWrapper>
            </SMPHeaderCon>

        </Fragment>
     );
}
 
export default SMPHeader;