import { Fragment } from "react";
import {
  MboaSumCon,
  MboaSumWrapper,
  MboaImgCon,
  MboaDescCon,
  MboaMainSumCon,
} from "./MboaSum.style";
import MboaPic from "../../assets/images/MboaPic.png";
import { Link } from "react-router-dom";
const MboaSum = (props) => {
  return (
    <Fragment>
      <MboaMainSumCon>
        <MboaSumCon>
          <MboaSumWrapper>
            <MboaImgCon>
              <img src={MboaPic} alt="MboaLab Group" />
            </MboaImgCon>
            <MboaDescCon>
              <h5 className="markdown">about mboalab</h5>
              <h2>All you need to know of MboaLab</h2>
              <p>
                MboaLab is an open and collaborative space located in Yaoundé,
                Cameroon. It is a laboratory for social innovation, community
                education, collaboration and mediation at the service of the
                common good. Operating as a research and applied Center for Open
                Science and Citizen Science, Mboalab was officially opened in
                December 2017. The objective is to catalyze sustainable local
                development and improve people’s living conditions through open
                science. By choosing education and health as our priority axes
                of intervention, in addition to its role as a mediator between
                Science-Technologies-Societies, we can say that Mboalab is a
                powerful catalyst for local sustainable development.
              </p>
              <button><Link style={{color:'white',textTransform:'uppercase',fontWeight:500}} to="/MboaLab">Read More</Link></button>
            </MboaDescCon>
          </MboaSumWrapper>
        </MboaSumCon>
      </MboaMainSumCon>
    </Fragment>
  );
};

export default MboaSum;
