import styled from "styled-components";

export const SubscribeContainer = styled.div `
display:flex;
flex-direction:column;
align-items:center;
padding-top:4rem;
justify-content:center;
margin:.1rem .5rem;
width: 40rem;
.validity{
    color:red;
    font-size:smaller;
    text-align:center;
}
@media screen and (max-width:920px){
    width:100%;
}
@media screen and (max-width:679px){
    margin:0;
}
`
export const SubscribeWrapper = styled.div `
display:flex;
justify-content:space-between;
margin:1rem 0;




@media screen and (max-width: 620px) {
flex-direction:column;
justify-content:space-between;
align-items:center;
gap:2em;
}

`
export const SocialLinksCon = styled.div `

display:flex;
justify-content:space-between;


`
export const SocialLink = styled.a `
color:white;
text-decoration:none;
font-size: 1.5rem;
padding:.8rem;

`

export const ButtonSend = styled.button `
color:#E0E0E0;
border-radius:2px;
border:2px solid #E0E0E0;
margin-left:1.3em;
font-size:1rem;
outline:none;
padding: .5em 2.8em;
background:none;
border-radius:2px;

@media screen and (max-width:678px){
    margin-left:0;
}

&:hover{
    border: 2px solid white;
    background:grey;
}

`
export const InputText = styled.input `
outline:none;
font-weight:500;
font-size:1rem;
border: 2px solid #E0E0E0;
background:none;
color: #E0E0E0;
padding: .8rem;
width:20rem;
border-radius:2px;

@media screen and(max-width:920px){
    width:100%;
}
&:focus{
    border:3px solid #E0E0E0;
}
.error{
    border: 2px solid red;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

`