import { Fragment } from "react";
import {ListCon,ListWrapper, TeamMemberCon,MemberImg,MemberInfoCon} from "./OurTeam.styled"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
const OurTeamList  = (props) => {
    return ( 
        <Fragment>
            <ListCon>      
                <ListWrapper> 
                    <TeamMemberCon>
                        <MemberImg src={props.memberImg} alt={props.memberName + " Image of"}/>
                        <MemberInfoCon>
                            <h5>{props.memberName}</h5>
                            <p>{props.memberRole}</p>
                            <div className="memberLinkCon">
                                <a className="links" href={props.memberLinkedin} target="_blank" rel="noopener noreferrer"><LinkedInIcon fontSize="small"/></a>
                                <a  className="links" href={`mailto:${props.memberEmail}`} target="_blank" rel="noopener noreferrer"><EmailIcon fontSize="small"/></a>
                            </div>
                        </MemberInfoCon>

                    </TeamMemberCon>

                </ListWrapper>
            </ListCon>
        </Fragment>
     );
}
 
export default OurTeamList ;