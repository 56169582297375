import { Fragment } from "react";
import styled from "styled-components"
import {CardWrapper} from "./Blog.style"
import lassa from "../../assets/images/Bro-Marco.jpg"
import { Link } from "react-router-dom";
const StoryLink = styled(Link)`
color: #424242;
text-decoration: none;
`;
const StoryCard = (props) => {
 
    return ( 
        <Fragment>
            <CardWrapper>
                <StoryLink to={`/news/${props.id}`}>
                <div className="imageCardCon">
                    <img src={props.imageLink || lassa} alt="lassa" />
                </div>
                <div className="detailCardCon">
                    <h4 className="newsTitle">{props.title || "Africa open science hardware for the makers in ghana and beyond"}</h4>
                    <h6  className="markdown">{props.date}</h6>
                </div>
                </StoryLink>
            </CardWrapper>

        </Fragment>
     );
}
 
export default StoryCard;