import { Fragment } from "react";
import { TanzaniaCon, TanzanianWrapper,TabletContainer,TabletImgVideo,Flask } from "./TanzanianSummit.style";
import Tablet from "../../assets/images/Tablet.png";
import limage from "../../assets/images/flask.png";

const TanzanianSummit = (props) => {
    return ( 
        <Fragment>
            <TanzaniaCon>
                <TanzanianWrapper>
                    <h5 className="markdown">Tanzanian Wrapper</h5>
                    <h2>Our Gathering in Tanzania</h2>
                    <p>For more background, check out this video from the previous gathering in Tanzania: </p>
                    <TabletContainer>
                        <TabletImgVideo>
                        <img src={Tablet} alt="Tablet"/>
                        <iframe src="https://www.youtube.com/embed/sYtT2zU6vMs" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </TabletImgVideo>
                    </TabletContainer>
                    <Flask src={limage} alt="limage" />
                </TanzanianWrapper>
            </TanzaniaCon>
        </Fragment>
     );  
}
 
export default TanzanianSummit;