import styled from "styled-components";

export const ItemMantraWrapper = styled.div `
display:flex;
flex-direction:column;

justify-content:space-space-between;

@media screen and (max-width:920px){
    margin: 3rem auto;
    width:100%;
    padding:0 3rem;
}
`
export const MainMantraCon = styled.div `
display:flex;
width:30%;



@media screen and (max-width:920px){
    margin: 0 auto;
    width:100%;
}
`
export const Description = styled.p `
padding: .4rem .1rem;
font-size: 1.25rem;
`
export const MantraHeader = styled.p `
color:#424242;
font-weight:bold;
font-size:1.65em;
`
export const FontBox = styled.div `
color:#424242;
font-size: 1.25rem;
padding:1rem .3rem
`
export const Firebox = styled.div `
display:grid;
align-items:center;
width:435px;
height:auto;
margin: 2em 4em;
border:1px solid grey

`