import { Fragment } from "react";
import AboutHeader from "../../components/AboutHeader/AboutHeader";
import WhatWeDo from "../../components/WhatWeDo/WhatWeDo";
import Values from "../../components/Values/Values";
import FooterOsh from "../../components/FooterOsh/FooterOsh"
import GridParnters from "../../components/GridPartners/GridPartners";
import OurTeamOsh from "../../components/OurTeam/OurTeam";
import FutureLeap from "../../components/FutureLeap/FutureLeap";
import AboutTopHeader from "../../components/AboutTopHeader/AboutTopHeader";

const About = (props) => {
  return (
    <Fragment>
      <AboutTopHeader/>
      <AboutHeader/>
      <WhatWeDo/>
      <Values/>
      <FutureLeap/>
      <OurTeamOsh/>
      <GridParnters/>  
      <FooterOsh/>   
    </Fragment>
  );
};

export default About;
