import { Grommet } from "grommet";
import { Fragment } from "react";
import { ButtonCon,Button } from "../Ideology/Ideology.style";
import {MainTextWrapper,ConnectCon, MainTextCon,ConnectWrapper,TextParagraphCon, Gimg,MainImgCon, MainConnectCon} from './ConnectWith.style'

const ConnectWith = (props) => {
    
    return ( 
        <Fragment>
            <Grommet background="light-2">
                <MainConnectCon>
                <div className="container">
                     <ConnectCon>
                       
                         <ConnectWrapper reverse={props.reverse}>
                          <MainTextCon>
                          <h6 style={{color:'#424242',textAlign:"left"}} className="markdown">{props.mainHeader}</h6>
                             <MainTextWrapper>
                            
                             <h3 className="main-header">{props.title}</h3>
                             </MainTextWrapper>
                            <TextParagraphCon>
                              <p className="Wtext-paragraph">{props.paragraph}</p>
                              <ButtonCon>
                <Button><a  target="_blank" rel="noreferrer"  href={props.btnLink}>{props.btnTitle}</a></Button>
              </ButtonCon>
                            </TextParagraphCon>
                            </MainTextCon>
                            <MainImgCon>
                                <Gimg  alt="group Osh" src={props.commImage}></Gimg>
                            </MainImgCon>
                         </ConnectWrapper>
                     </ConnectCon>
                </div>
                </MainConnectCon>
            </Grommet>
        </Fragment>
     );
}
 
export default ConnectWith;