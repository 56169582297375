import { Fragment } from "react";
import { TrendingContainer, TrendingWrapper, InfoBlack, ImageContainer, HeaderInfo } from "./Blog.style";
import hotNews from "../../assets/images/hotnews.jpg";
const BlogCard = (props) => {
  return (
    <Fragment>
      <TrendingContainer>
        <TrendingWrapper>
          <ImageContainer>
            <img src={hotNews} alt="snached" />
          </ImageContainer>
          <HeaderInfo>
            <div className="headingCon">
              <InfoBlack className="blackText">
                Digital Fabrication is transforming production and consumption
              </InfoBlack>
            </div>
          </HeaderInfo>
        </TrendingWrapper>
      </TrendingContainer>
    </Fragment>
  );
};

export default BlogCard;
