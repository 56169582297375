import styled from "styled-components";
export const BlogCon = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const NavHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(70% - 1.2rem);
  margin-top: 2rem;

  @media screen and (max-width: 920px) {
    width: 80%;
    padding: 0;
  }
`;
export const BlogTextHeader = styled.div `
  padding-bottom: 1rem;
`;
export const NewsCon = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
  flex-wrap: wrap;

  @media screen and (max-width: 920px) {
    width: 100%;
  }
`;
export const NewsWrapper = styled.div `
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 920px) {
    min-width: 15rem;
    width: 22rem;
  }
`;
export const NewsSection = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: clac(100% / 3 + 5px);
  transition: all 0.093s ease-in-out;

  &:hover {
    background-size: 100% 2px;
    transition: all 0.4s ease-in-out;
  }
  @media screen and (max-width: 920px) {
    width: 100%;
  }
`;
export const NewsImgCon = styled.div `
  width: inherit;
  height: 10rem;
  @media screen and (max-width: 920px) {
    width: 100%;
  }
`;
export const NewsImg = styled.img `
  width: 100%;
  height: 10rem;
  object-fit: cover;
  flex-shrink: 0;
`;
export const NewsHeaderTextCon = styled.div `
  width: 100%;
  height: 4rem;
  cursor: pointer;
  @media screen and (max-width: 970px) {
    width: 100%;
  }
`;
export const NewsHeaderText = styled.h3 `
  min-width: 10rem;
  text-decoration: none;
  text-align: left;
  transition: all 0.3s ease;
  position: relative;
  color: #424242;
  text-align: left;
  font-weight: 700;

  &:hover {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: black;
    text-decoration: underline;
  }
`;
export const NewsDateCon = styled.div `
  width: 100%;
  height: 36%;
  display: inline-block;
  margin-top: 0.4rem;
  object-fit: cover;
  display: flex;
`;
export const NewsDate = styled.p `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #828282;
  align-self: flex-end;
`;
export const NewsImageInfo = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .margin {
    margin: 2.4rem 0;
  }
`;
export const NewsBoxCon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;
export const NewsBoxWrapper = styled.div `
  display: flex;
  width: 70%;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 920px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const NewsDetailCon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const NewsDetailWrapper = styled.div `
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: space-around;
`;
export const NewsDetailTitle = styled.div `
  margin: 2rem;

  h3 {
    color: #424242;
    text-align: center;
    font-size: 1.9rem;
    letter-spacing: normal;
    font-weight: 700;
    padding-bottom: 2rem;
  }
`;
export const NewsDetailHeaderBox = styled.div `
  width: 70%;
`;
export const DetailInfo = styled.div `
  display: flex;
  align-items: flex-start;
  span {
    margin: 0 1rem;
    color: #828282;
  }
  p {
    padding-right: 1rem;
    font-size: 0.802rem;
    color: #828282;
    text-transform: uppercase;
  }
`;
export const DetailInfoImgCon = styled.div `
  width: 100%;
  height: 40rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
export const NewsDetailsInfoCon = styled.div `
  margin: 1.5rem 0;
  ol {
    display: flex;
    flex-direction: column;
    list-style-position: outside;
    align-items:flex-start;
    
    margin:1.5rem 0;
  }
  li {
    list-style-position: inside;
    display: list-item;
    font-family: "Karla", sans-serif;
  }
  p {
    color: #5b6278;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
`;