import React from "react";
import { Fragment } from "react";
import {
    AboutHeaderCon,  
    MainAboutInfoCon,
    ShortDesc,
  } from "../AboutHeader/AboutHeader.style";
import  { AboutTopHeaderCon, FirstCon, SecondCon } from "./AboutTopHeader.style";
import topabout from "../../assets/images/topabout.jpg"
const AboutTopHeader = (props) => {
  return (
    <Fragment>
        <AboutHeaderCon style={{backgroundColor: '#131313'}}>
          <MainAboutInfoCon>
          <ShortDesc>know more about AfricaOSH</ShortDesc>
          <h3 style={{color:'white'}} className=" main-header">About Us</h3>
        </MainAboutInfoCon>
        </AboutHeaderCon>
      <AboutTopHeaderCon>
        <FirstCon>
      <img src={topabout} alt="topabout" />
        </FirstCon>
        <SecondCon>
            <h3>Get to know about your identity</h3>
        <p>
            The Africa Open Science and Hardware (AfricaOSH) Community is a
            pan-African community of makers, researchers, and educators who are
            dedicated to advancing open science and hardware across the
            continent. The community was established in 2016 as a response to
            the need for more inclusive and accessible approaches to science and
            technology in Africa.
          </p>
          <p>
            The AfricaOSH Community is committed to promoting open and
            collaborative approaches to scientific research and innovation, and
            to democratizing access to tools and technologies that can help
            solve local challenges. The community is also focused on building
            capacity in open science and hardware, supporting local
            entrepreneurship, and fostering international collaborations.
          </p>
          <p>
            To achieve its goals, the AfricaOSH Community organizes various
            activities and events, such as workshops, training sessions, and
            hackathons, to bring together individuals from diverse backgrounds
            and disciplines to share knowledge and skills. The community also
            provides online resources, including a directory of open science and
            hardware initiatives in Africa, a forum for discussion and
            collaboration, and an open source repository of projects and
            designs.
          </p>
        </SecondCon>
      </AboutTopHeaderCon>
    </Fragment>
  );
};

export default AboutTopHeader;
