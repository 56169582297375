import { Fragment } from "react";
import { useParams } from "react-router-dom";
import SMPHeader from "../../components/SMPHeader/SMPHeader";
import SMPStyle from "../../components/SMPStyle/SMPStyle";
const SummitPage = (props) => {
    const params = useParams();
    return ( 
        <Fragment>
            <SMPHeader/>
            <SMPStyle/>
        </Fragment>
     );
}
 
export default SummitPage;