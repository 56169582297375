import OshImg from "../../assets/images/osh.png"
import React, { Fragment,useState,useEffect } from "react";
import { Footer,Text } from "grommet"
import { MailOption } from "grommet-icons";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import useHttp from "../../hooks/use-http";
import { FooterSpan,ContactInfoCon,MainFooterWrapper, FooterLogoImg, MainFooterCon,ColumnCon,FooterHeaderText } from "./FooterOsh.style";
import Subscribe from "../Subscribe/Subscribe";
const FooterOsh = React.forwardRef((props,ref) => {

  const { isLoading, error,sent, sendRequest: sendEmailRequest } = useHttp();
  const createEmailList = (value, taskData) => {
    //const generatedId = taskData.name; // firebase-specific => "name" contains generated id
   // const createdTask = { id: generatedId,  email: value };

    //you can choose to pass an email list below by adding props.emailadd
  };
const year = new Date();


const emailSendHandler = async (value) => {
  sendEmailRequest({
    
      url: 'https://aosh-db-default-rtdb.firebaseio.com/EmailList.json',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { email: value },
    },
    createEmailList.bind(null,value)
  );
}

const [open , setOpen] = useState(false)
  
useEffect(() => {
  if(sent === true) {
    setOpen(true)
  }else {
    setOpen(false)
  }
}, [sent])


  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;
    setOpen(false);
    
  };
  
  // const handleClickEvent = () => {
  //   setOpen(true);
  // };



  return (
    <Fragment>
      <MainFooterCon top={props.margin} id={props.id} ref={ref}>
        <MainFooterWrapper>
          <ColumnCon>
            <FooterLogoImg alt="AfricaOSH logo" src={OshImg}></FooterLogoImg>
          </ColumnCon>
          <ColumnCon>
            <FooterHeaderText>Community Members</FooterHeaderText>
            <a  href="https://kumasihive.com/">
              Kumasi Hive
            </a>
            {/* <a href="https://sticlab.com/">
            STICLab
            </a> */}
            <a  href="https://website-mboalab.vercel.app/">
              MboaLab
            </a>
            <a  href="https://www.projetsoha.org">
              APSOHA
            </a>
            <a  href="https://btech.co.tz">
              Bongo Tech & Research Lab
            </a>
            <a href="https://africamakerspace.net/">
            AMN
            </a>
            <a  href="https://info.africarxiv.org/">
              AfricArXiv
            </a>
          </ColumnCon>
          <ColumnCon>
            <FooterHeaderText>Project Partners</FooterHeaderText>
            <a  href="https://glabghana.wordpress.com/">
              Global Lab Network
            </a>
            <a href="https://openhardware.science/">
              GOSH
            </a>
            <a href="https://the-exploratory.org/">
              The Exploratory
            </a>
            <a href="https://makeafricaeu.org/">
              mAkE
            </a>
            <a href="https://globalinnovationgathering.org/">
              GIG
            </a>
            <a href="https://jogl.io/">
            JOGL
            </a>
                       
          </ColumnCon>
          <ColumnCon>
            <FooterHeaderText>Contact Us</FooterHeaderText>
            {/* <ContactInfoCon>
              <Phone color="#fff"  style={{marginRight:"1rem"}}/>
              <FooterSpan href="tel:+233249336773">024 933 6773</FooterSpan>
            </ContactInfoCon> */}
            {/* <ContactInfoCon>
              <Phone color="#fff" style={{marginRight:"1rem"}}/>
              <FooterSpan href="tel:+233543842518" > 054 384 2518</FooterSpan>
            </ContactInfoCon> */}
            <ContactInfoCon>
              <MailOption color="#fff" style={{marginRight:"1rem"}}/>
              <FooterSpan href="mailto:team@africaosh.com">team@africaosh.com</FooterSpan>
            </ContactInfoCon>
          </ColumnCon>
        </MainFooterWrapper>
       <Subscribe onEnterInputEmail={emailSendHandler} loading={isLoading}/>
       <Snackbar  
  anchorOrigin={{
    horizontal: "left",
    vertical: "bottom",
  }}
  open={open}
  autoHideDuration={1000}
  message={error ? error.message : 'Email Sent'}
  onClose={handleToClose}
  action={
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
   
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  }
/>
      <p style={{color:'white'}}>{error}</p>
      </MainFooterCon>
      <Footer  style={{padding:"1rem"}} background={{color:"#1F1F1F"}} justify="center" align="center">
        <Text>Copyright © {year.getFullYear()} by Kumasi Hive. All rights reserved. </Text>
      </Footer>
    </Fragment>
  );
});

export default FooterOsh;
