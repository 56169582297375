import styled from "styled-components";
import makeban from "../../assets/images/makeBG.jpg";

export const MainConWrapper = styled.div `
  background-color: #f2f2f2;
`;

export const BannerContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width:679px){
    text-align:center;
    h5 ,h3 {
      text-align:center;
    }
    h3{
      width:85%;
    }
  }
`;
export const ProjectContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ProjectWrapper = styled.div `
  width: 70%;
  background-repeat: no-repeat;
  background-image: url(${makeban});
  background-size: cover;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  @media screen and (max-width: 679px) {
    width: 80%;
    justify-content: center;
    
  }
  @media screen and (max-width: 369px){
    background-image:none;  
  }
  h3 {
    color: white;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 1.25px;
    font-weight: normal;
  }
  img {
    width: 100%;
    max-width: 14rem;
  }
  .bText {
    color: white;
    font-size: 1.25rem;
    font-weight: 400;
  }
  .project-detail {
    background: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    padding: 3rem 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btnMore {
    color: #424242;
    outline: none;
    border: none;
    text-decoration: none;
    padding: 0.63rem 1rem;
    text-transform: uppercase;
    background-color: white;
    margin: 2rem;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: normal;
    border-radius: 0.3rem;
    letter-spacing: 0.1em;
    cursor: pointer;
  }
`;
export const DetailWrapper = styled.div `
  border-radius: 32px;
  position: absolute;
  right: 13rem;
@media screen and (max-width:1300px){
  right:3rem;
}
  @media screen and (max-width: 967px) {
    position: relative;
    right:1rem;
 
  }
  @media screen and (max-width: 679px) {
    position: relative;
    align-self:center;
    
  }
`;
export const ProjectDetail = styled.div `
  background: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width:679px){
    padding:1.5rem 3rem;
    .projectLogo { 
      display:none;
    }
  }

  .detailWrapper {
    border-radius: 2px;
    position: absolute;
    right: 13rem;
  }
  .btnMore {
    color: #424242;
    outline: none;
    border: none;
    text-decoration: none;
    padding: 0.63rem 1rem;
    text-transform: uppercase;
    background-color: white;
    margin: 2rem;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: normal;
    border-radius: 0.3rem;
    letter-spacing: 0.1em;
    cursor: pointer;
  }
`;