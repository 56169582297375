import styled from 'styled-components';

export const EventViewCon = styled.div `
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
margin-bottom:2.3rem;

@media screen and (max-width:920px){
  margin:1rem 6rem;  
}
@media screen and (max-width:679px){
   margin:1rem 4rem;
}

`
export const EventViewInfoCon = styled.div `
color:#424242;


`
export const EventViewWrapper = styled.div `
display:flex;
margin:2rem;
max-width:65%;
width:100%;
@media screen and  (max-width:920px){
    flex-direction:column;
    margin:1rem;
    padding: .1rem .1rem;
    max-width:100%;
   
}

`
export const EventImgCon = styled.div `

width: 50%;
display:flex;
justify-content:center;
@media screen and (max-width:920px) {
    width:100%;
}
`
export const EventImg = styled.img `
object-fit:contain;
width:60%;
height:auto;



`
export const EventTextCon = styled.div `
text-align:left;
width: 50%;
padding-top:5rem;

@media screen and (max-width:920px) {
    width:100%;

}

`