import styled from "styled-components";


export const CommunityWrapper = styled.div ` 
 display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  position:relative;
  overflow:hidden;
  height:110vh;
  &::before{
    content:'';
    position:absolute;
    bottom:0;
    left:50%;
    width:100vw;
    height:100vh;
    background-color:#131313;
    border-radius:50%;
    transform-origin:bottom;
    transform:translate(-50%) scale(3);
    z-index:-1;
  }
`
export const CommunityPageCon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
margin-top:4rem;
z-index:1;

  h3 {
    width: 70%;
    text-align: center;
  }
`;
export const ConPic = styled.div `
  display: flex;
  justify-content: center;


`;
export const CommunityPic = styled.div `
display: flex;
  justify-content: center;

  width: 100%;
  margin-bottom: 2rem;
  img {
    width: 100%;
    display: block;
    object-fit: contain;
  }
`;

export const ThumbFrame = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  align-self: center;
  width: 70%;

`;
export const WidthCon = styled.div `
  display: flex;
  justify-content: center;
`;
export const ThumCon = styled.div `
  height: 26rem;
  margin-top:4rem;

  .disImg {
    height:14rem;
  }
  img {
    width: 100%;
    height:inherit;
    object-fit: cover;
    display: block;
  }
  .infoCon {
    height: 100%;
    max-height: 11.7rem;
    width: 100%;
    padding-top:.4rem;
    background-color: #f5f5f5;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
  }
  .subheader {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.7rem;
    letter-spacing: 0.2px;
    color: #828282;
  }
  .mainheader{
    margin-top:.4rem;
    margin-bottom:.4rem;
  }
  h3 {
    transition: all 0.3s ease;
    position: relative;
    color: #424242;
    text-align: left;
    font-weight: 700;
    cursor: pointer;
    &:hover{
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color:#1d1d1d;
      text-decoration:underline;
    }
  }
  .line{
    margin-top:.6rem;
    margin-bottom:.1rem;
    color:#9D9A9A;
  }
  .tilewrapper{
    padding-left:.65rem;
    display:flex;
    justify-content:flex-start;
    gap:1em;
  }
  .tile{
    display:flex;
    justify-content:center;
    align-items:center;
    padding:.32rem;
    /* width:2.7rem;
    height:2.7rem; */
    background: #E0E0E0;
    border-radius: 2px;
  }
`;
export const CommunityImage = styled.div ` 
/* display:flex;
justify-content:center;
align-items:center; */
width:70%;
margin-bottom:2rem;

img{
  display:block;
  object-fit:cover;
  width:100%;
  }
`
export const CommuHeader = styled.h4 `
color:#424242;
text-align:left;
padding-top:.5rem;
padding-bottom:.5rem;
line-height:1.2rem;
font-size:1.15rem;
letter-spacing:.033rem;
`

export const TextCommu = styled.p `
text-align:left;
padding-top:.5rem;
padding-bottom:.5rem;
width:70%;
color: #828282;
font-size: 1.25rem;
font-weight: 400;
margin-bottom:2rem;
`
export const BannerImgCon = styled.div `
width:70%;
position: relative;
display:flex;
justify-content:center;
align-items:center;
position: relative;

.overlaycon{
   padding:2rem 4rem;
  position:absolute;
    background: linear-gradient( 116.38deg, #000000 11.14%, rgba(0, 0, 0, 0.88208) 23.01%, rgba(0, 0, 0, 0.752962) 73.52%, rgba(0, 0, 0, 0) 88.64% );
}

img{
  display:block;
  width:100%;
  height:19rem;
  object-fit:cover;
  
}
`
export const TemplateIcon = styled.div ` 
 .tilewrapper {
      display:flex;
    justify-content:flex-start;
    gap:1em;
  }
  .tile{
    display:grid;
    align-self:center;
    width:1.3rem;
    height:1.3rem;
    color:white;
  }

`