import styled from "styled-components";

export const ContactPageCon = styled.div `
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin-top:1rem;
margin-bottom:6rem;
`
export const ContactPageWrapper = styled.div `
width:70%;
display:flex;
justify-content:center;
align-items:center;
@media screen and (max-width:920px){
    width:100%;
    flex-direction:column;
}
`
export const InfoColumnCon = styled.div `
width:50%;
padding:1rem;

display:flex;
flex-direction:column;

`
export const IconCon = styled.div `
background: #f2f2f2;
border-radius:2px;
padding:.5em .5em;
width:fit-content;
display:flex;
justify-content:center;
align-items:center;
border-radius:2px;

`