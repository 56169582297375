// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

    apiKey: "AIzaSyBsLBnfvsrKRvTT4_dnL1GgiKIrKijGoGM",

    authDomain: "aosh-db.firebaseapp.com",

    databaseURL: "https://aosh-db-default-rtdb.firebaseio.com",

    projectId: "aosh-db",

    storageBucket: "aosh-db.appspot.com",

    messagingSenderId: "79698706663",

    appId: "1:79698706663:web:cdc676d5fef41f55a56242",

    measurementId: "G-6Y2W2HE919"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
var storage = getStorage(app);

export default storage;