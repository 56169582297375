import { Fragment } from "react";
import { HostedSummitsWrapper,HostedSummitsCon } from "./HostedSummits.style";
import africaMap from '../../assets/images/blackmap.jpg'
const HostedSummits = (props) => {
    return ( 
        <Fragment>
            <HostedSummitsCon>
                <HostedSummitsWrapper>
                  <h5 className="markdown">previous hosted summits</h5>
                  <h2 className="hostedTitle">Open Science and its importance in developing countries</h2>
                  <p className="hostedDesc">The first Summit was held at the Kumasi Hive in 2018. The next Gathering was held at STICLab (now known as Bongo Tech & Research Lab) in Tanzania in 2019. With an aim of growing the community. Now the next gathering is set to be held at MboaLab in Yaoundé, Cameroon in 2022!</p>
              <div className="imgContainer">
                <img src={africaMap} alt="africaMap" />
              </div>
                </HostedSummitsWrapper>
            </HostedSummitsCon>
        </Fragment>
     );
}
 
export default HostedSummits;