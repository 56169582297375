import { Fragment } from "react";
import EventHeader from "../../components/EventHeader/EventHeader";
import FooterOsh from "../../components/FooterOsh/FooterOsh"

const Event = () => {
    return ( 
        <Fragment>
            <EventHeader/>
            <FooterOsh></FooterOsh>
        </Fragment>
     );
}
 
export default Event;