import styled from "styled-components";

export const GridPartnerCon = styled.div `
display:flex;
justify-content:center;
align-items:center;
width:82%;
margin:0 auto;
height:auto;
flex-wrap:wrap;


`
export const GridPartnerWrapper = styled.div `
display:flex;
flex:25%;
justify-content:center;
align-items:center;

`
export const GridTextHeader = styled.div `
margin:2em 1em;
display:flex;
justify-content:center;
flex-direction:column;

`