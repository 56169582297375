import styled from "styled-components";

export const PartnerContainer = styled.div `
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;


`
export const PartnerWrapper = styled.div `
display:flex;
text-align:center;

`
export const Restrict = styled.div `

width: 70%;
margin-bottom:4rem;
/* cursor:; */

@media screen and (max-width:920px) {
    width:100%
}
`
export const PartnerImgCon = styled.div `
width:150px;
height:100px;
display:flex;
align-items:center;
justify-content:center;
margin:2rem;

`
export const PartnerImg = styled.img `
object-fit:contain;
height:100%;
width:100%;
`