import styled from "styled-components";

export const HostedSummitsCon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const HostedSummitsWrapper = styled.div `
  width: 70%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  .hostedTitle {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    color: #424242;

    @media screen and (max-width:967px){
      text-align:center;
    }
  }



  .hostedDesc {
    color: #828282;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: center;
    margin-top: 1.5rem;
    padding-left:2rem;
    padding-right:2rem;
  }
  img {
    display: block;
    object-fit: contain;
    width: 100%;
  }
  .imgContainer {
    margin-top:3rem;

  }
`;