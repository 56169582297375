import { Fragment } from "react";
import FooterOsh from "../../components/FooterOsh/FooterOsh";
import makeLogo from "../../assets/images/makeLogo.png";

import {
  MainAboutInfoCon,
  ShortDesc,
} from "../../components/AboutHeader/AboutHeader.style";
import {
  ProgramBannerCon,
  ProgramBannerImg,
  ProgramBlock,
  EventHeaderCon,
  MakeBannerImg,
  BannerDetailCon,
  ProjectDetailWrapper,
  BannerWrapper,
} from "./ProjectDetail.styled";
import BannerEventImg from "../../assets/images/alice.jpg";
const ProjectDetail = (props) => {
  const eulogo = "https://ec.europa.eu/regional_policy/images/information/logos/eu_flag.jpg";
  return (
    <Fragment>
      <ProjectDetailWrapper>
        <EventHeaderCon>
          <MainAboutInfoCon>
            <ShortDesc>EU-Funded projects</ShortDesc>
            <h3 className="blackText main-header">African European Maker Innovation Ecosystem</h3>
          </MainAboutInfoCon>
        </EventHeaderCon>
      </ProjectDetailWrapper>
      <BannerWrapper>
        <ProgramBannerCon>
          <ProgramBannerImg src={BannerEventImg} alt="mAke banner" />

          <ProgramBlock>
            <div>
              <MakeBannerImg src={makeLogo} alt="make Logo" />
            </div>
          </ProgramBlock>
        </ProgramBannerCon>
      </BannerWrapper>
      <BannerDetailCon>
        <div className="caseCon">
          <p>
            The EU-funded African European Maker Innovation Ecosystem (mAkE) project promotes cooperation and strategic
            partnership with countries in Africa and in Europe to support the
            strengthening of existing digital innovation hubs (DIHs) in Africa
            and Europe. Its overall aim is to facilitate the collaboration
            between EU and African DIHs to strengthen a common EU-Africa
            innovation and start-up ecosystem. AfricaOSH is a consortium member
            of this project.
            </p>
            <br />
            <p>
             AfricaOSH will support activities and deliverables
            under several work packages and will support lead organisations to
            execute projects under these work packages in our capacity as an
            open network. In particular, AfricaOSH will lead the anglophone
            assembly of Open Educational Resources (OER) for the mAkE MOOC
            (Massive Open Online Course).
            </p>
            <br />
            <p> 
            Furthermore, AfricaOSH will input its
            community of experts and expertise in the co-creation processes in
            connection to Work Packages. The annual AfricaOSH Summits will be
            used to host co-creation sessions and other mAkE meetings and
            events. <br /> This project has received funding from the European Horizon
            2020 programme under grant agreement No 101016858) 
            <div className="euLogoCon">
            <img src={eulogo} alt="European Union Logo" />
            </div>
            </p>
            <br />
          <div>
          <a href="https://makeafricaeu.org/">Find out more
            about the mAkE project <strong>here.</strong></a>
            </div>
        </div>
       
      </BannerDetailCon>
      <FooterOsh />
    </Fragment>
  );
};

export default ProjectDetail;
