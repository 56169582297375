import { Fragment } from "react";
import  FooterOsh  from "../../components/FooterOsh/FooterOsh"
import SummitsHeader from "../../components/SummitsHeader/SummitsHeader";

const Summits = () => {
    return ( 
        <Fragment>
            <SummitsHeader/>
            <FooterOsh/>
        </Fragment>
     );
}
 
export default Summits;