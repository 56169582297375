import styled from "styled-components";


export const ParallaxCon = styled.div `

display:flex;
flex-direction: column;
justify-content:center;
align-items:center;


.pcontainer {
    margin: 10rem 0;
    padding:1.5rem;
    width:70%;
    display:flex;
    border-radius:3px;
    flex-direction:column;
    justify-content:center;
    align-content:center;
    background: rgb(1,1,6);
    background: linear-gradient(90deg, rgba(1,1,6,1) 0%, rgba(22,22,26,1) 41%, rgba(33,33,34,1) 58%, rgba(61,61,63,1) 77%, rgba(65,65,65,1) 100%); 

.wrapper{
    display:flex;
    align-items:baseline;
    justify-content:center;
   flex-wrap:wrap;

}
    @media screen and (max-width:679px){
      width:100%;
     
    }
}
`
export const AchievementCon = styled.div `
display:flex;
margin:2rem 2.3rem;
flex-direction:column;


.space{
   text-align:center;
   color:#CCC;
   margin:.4rem;
}
@media screen and (max-width:679px) {
    flex-wrap:wrap;
    margin:1rem;
}

`
export const WhiteText = styled.p `
font-size:small;
color:#EDEDED;
font-weight:normal;


`
export const ShootingNumber = styled.p ` 
color:white;
font-weight:bolder;
text-align:center;
font-size:2.5rem;


`