import { Fragment } from "react";

import {
  AboutHeaderImg,
  AboutHeaderConImg,
  AboutHeaderCon,
  AboutHeaderWrapper,
  ContentInfo,

} from "./AboutHeader.style";
import abtImg from "../../assets/images/imgAbout.jpg";

const AboutHeader = (props) => {
  return (
    <Fragment>
      <AboutHeaderCon>
        <AboutHeaderWrapper
          background={"neutral-1"}
          alignContent="center"
          direction="row"
          align="center"
        >

          <ContentInfo>
            <p className="Wtext-paragraph">
              The AfricaOSH Community has made significant contributions to the
              advancement of open science and hardware in Africa, including the
              development of low-cost, open source scientific instruments, the
              establishment of local maker spaces, and the promotion of open
              access to research publications.
              <br />
              <br />
              
              Overall, the AfricaOSH Community is a vibrant and inclusive
              community that is committed to promoting open and collaborative
              approaches to science and technology in Africa, and to building
              capacity in open science and hardware across the continent.
            </p>
          </ContentInfo>
          <ContentInfo>
            <AboutHeaderConImg>
              <AboutHeaderImg src={abtImg} />
            </AboutHeaderConImg>
          </ContentInfo>
        </AboutHeaderWrapper>
      </AboutHeaderCon>
    </Fragment>
  );
};

export default AboutHeader;
