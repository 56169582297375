import { Fragment } from "react";
import { SMPstyleWrapper,SMPstyleCon  } from "./SMPStyle.style";
import handImage from "../../assets/images/hand.jpg"
const SMPStyle = (props) => {
    return ( 
        <Fragment>
            <SMPstyleCon>
                <SMPstyleWrapper>
<div className="ImageCon">
    <img src={handImage} alt="moderation" />
</div>
<div className="descCon">
    <h5 style={{textAlign:'left'}} className="markdown">Style of our summits</h5>
     <h2 className="sumTitle">Summit structuring</h2>
     <p >A majority of the summit sessions are planned and created before participants arrive. Only a basic structure is not -planned.
Because everything we do is open-source, we even put together a free, beautiful book to show how you can run an unconference like ours!</p>
</div>
                </SMPstyleWrapper>
            </SMPstyleCon>

        </Fragment>
     );
}
 
export default SMPStyle;