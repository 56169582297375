import { Text } from "grommet";
import { Fragment } from "react";
import { WhatWrapper, WhatContainer, Space } from "./WhatWeDo.style";

const WhatWeDo = (props) => {
  return (
    <Fragment>
      <WhatContainer>
        <WhatWrapper>
          <h3 className="main-header" style={{ color: "#131313" }}>
            What We Do
          </h3>
          <Text color={"#424242"} style={{ fontSize: "1.25rem" }}>
            {/* We collaborate with innovators, makers and makerspaces, government
            institutions and everyone interested in open science to help
            accelerate innovation in Africa. We seek to create a set of actions
            among Africans and in Africa about open science hardware and its
            importance to Africa. We provide a platform for makers, makerspaces,
            innovators, and all open science enthusiasts from all over Africa to
            showcase their ideas, innovations, prototypes, etc. at our annual
            summits, where they can also connect and meet with potential
            investors and collaborators. */}
            AfricaOSH is a not-for-profit organization that seeks to create a
            set of actions and conversations in open science hardware among
            African open science actors, researchers, policymakers, digital
            innovation hubs, etc. We want to provide the biggest platform where
            Africans can showcase their innovations and ideas, make it easy for
            collaborations, and also meet potential investors while also
            educating Africa on the enormous benefits and importance of open
            science hardware. AfricaOSH is a community that is open to all
            Africans and non-Africans interested in open science and open
            science hardware.
            <Space>
              <strong>Why open science hardware</strong>
              <ul>
                <li>
                  Open science hardware reduces the need to rely on expensive
                  infrastructure for the scientific journey
                </li>
                <li>It’s democratic & inclusive</li>
                <li>
                  It promotes community and citizen science and the
                  Do-It-Yourself (DIY) culture
                </li>
                <li>
                  It has the potential to transform education, research, science
                  & innovation
                </li>
              </ul>
            </Space>
          </Text>
        </WhatWrapper>
      </WhatContainer>
    </Fragment>
  );
};

export default WhatWeDo;
