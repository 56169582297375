import { Fragment } from "react";
import SMPHeader from "../../components/SMPHeader/SMPHeader";
import SMPStyle from "../../components/SMPStyle/SMPStyle";
import FormatStyle from "../../components/FormatStyle/FormatStyle";
import AboutSummary from "../AboutSummary/AboutSummary";
import HostedSummits from "../HostedSummits/HostedSummits";
import TanzanianSummit from "../TanzanianSummit/TanzanianSummit";
import MboaSum from "../MboaSum/MboaSum";
import FooterOsh from "../FooterOsh/FooterOsh";

const AboutContent='Africa Open Science Hardware (AfricaOSH) is a community of makers, researchers, makerspaces, hackers, policymakers, scientists, and more dedicated to making open science hardware ubiquitous by 2025 and promoting open science in Africa. Science hardware encompasses the tools and machinery we use for scientific endeavors (for example, a microscope or environmental sensor). Open Science Hardware refers to science hardware that is open source – or free to use, change, study, or distribute. By making science hardware open, more people can have access to the tools we use to do science. Find out more by visiting the AfricaOSH website!'
const MainSummitPage = (props) => {
  return (
    <Fragment>
      <SMPHeader />
      <FormatStyle/>
      <SMPStyle />
      <AboutSummary content={AboutContent}/>
      <HostedSummits/>
      <TanzanianSummit/>
      <MboaSum/>
      <FooterOsh/>
    </Fragment>
  );
};

export default MainSummitPage;
