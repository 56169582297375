import { Fragment } from "react";
import useHttp from "../../hooks/use-http";
import { Clock, Location } from "grommet-icons";
import {Text } from "grommet";
import FooterOsh from "../../components/FooterOsh/FooterOsh";
import ContactForm from "../../components/ContactForm/ContactForm";
import { MainAboutInfoCon,ShortDesc } from "../../components/AboutHeader/AboutHeader.style";
import {
  ContactPageCon,
  ContactPageWrapper,
  InfoColumnCon,
  IconCon
} from "./Contact.style";
import { HeaderContactText } from "../../components/ContactForm/ContactForm.style";
const Contact = () => {
  const { isLoading, error,sent, sendRequest: formRequest } = useHttp();
  const createFormList = (value, taskData) => {
 //   const generatedId = taskData.name; // firebase-specific => "name" contains generated id
  

    //you can choose to pass an email list below by adding props.emailadd
  };
  const sendFormRequest = async (formValue) => {
    formRequest({
      
        url: 'https://aosh-db-default-rtdb.firebaseio.com/ContactForm.json',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { contact: formValue },
      },
      createFormList.bind(null,formValue)
    );
  }

  return (
    <Fragment>
      <ContactPageCon>
          
        <MainAboutInfoCon>
        <ShortDesc>Connect with  AfricaOSH</ShortDesc>
            <h3 className="blackText main-header">Contact Us</h3>
        </MainAboutInfoCon>
        <ContactPageWrapper>
          <InfoColumnCon>
              <HeaderContactText>Find Us</HeaderContactText>
              
            <Text style={{color:"rgb(91, 98, 120)",paddingBottom:"1em"}}>
            <IconCon>
              <Location nfocolor='plain' size='medium' /> 
              </IconCon>
            
              <strong style={{color:"#424242"}}>Our Corporate Headquarters is located at:</strong>
              <br/>Hse 49 - SE 29056 Drive,
              <br/> 2nd Turn Behind Mizpah School
              <br/>Kentinkrono - Kumasi <br/>
            </Text>
            <Text style={{color:"rgb(91, 98, 120)"}}>
            <IconCon>
              <Clock color='plain' size='medium' /> 
              </IconCon>
              <strong style={{color:"#424242"}}>Working hours</strong><br/>
               Monday – Friday, 9:30 am – 5:00 pm GMT
              </Text>
          </InfoColumnCon>
          <InfoColumnCon>
            <ContactForm sent={sent} error={error} isLoading={isLoading} onEnteredFormValue={sendFormRequest}/>
          </InfoColumnCon>
        </ContactPageWrapper>
      </ContactPageCon>
      <FooterOsh />
    </Fragment>
  );
};

export default Contact;
