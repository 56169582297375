import styled from 'styled-components';

export const ProjectDetailContainer = styled.div `
  
`
export const EventHeaderCon = styled.div `
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

`

export const ProgramsWrapper = styled.div `
display:flex;
flex-direction:column;
width:70%;
margin:0 auto;


`

export const ProgramBannerCon = styled.div `
width:100%;
height:100%;
position:relative;
display:flex;


`
export const ProgramBannerImg = styled.img `
width:100%;
object-fit:content;
height: 100%;


`
export const ProgramBlock = styled.div `
width: 47%;
height: inherit;
position:absolute;
left:53%;
top:0%;
background: linear-gradient(196.38deg, #000000 -19.14%, rgba(0, 0, 0, 0.88208) 23.01%, rgba(0, 0, 0, 0.752962) 73.52%, rgba(0, 0, 0, 0) 88.64%);


div{
display:flex;
justify-content:center;
align-items:center;
height:inherit;
width:inherit;

width:100%;
padding:3rem;
}
h3{

    color:white;
    display:inline-block;
    font-size:2.1vw;

}
`
export const ProjectDetailWrapper = styled.div `
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`
export const BannerWrapper = styled.div `
display:flex;
align-items:center;
justify-content:center;
width:70%;
flex-direction:column;
margin: 2em auto;

`
export const MakeBannerImg = styled.img `
width:100%;
max-width:16rem;
`

export const BannerDetailCon = styled.div `
width:70%;
display:flex;
justify-content:center;
align-items:center;
margin:1.5rem auto;

.euLogoCon{
  width:6rem;
  max-width:100%;
  
}
img{
  object-fit:contain;
  width:100%;
  display:block;
}
p {
  color: #5b6278;
  font-size: 1.25rem;
  font-weight: 400;
  line-height:2rem;
}

`