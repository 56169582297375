import { Fragment } from "react";
import { FormatCon, FormatWrapper, TitleCon,Gline } from "./FormatStyle.style";
const FormatStyle = (props) => {
  return (
    <Fragment>
      <FormatCon>
        <TitleCon>
          <h5 className="markdown" style={{color:'#424242'}}>Format of the summit</h5>
        </TitleCon>
        <FormatWrapper>
          <div className="Unconference">
            <p>Unconference</p>
          </div>
          <Gline/>
          <div className="Workshops">
            <p>Workshops</p>
          </div>
          <Gline/>
          <div className="Presentations">
            <p>Presentations</p>
          </div>
        </FormatWrapper>
      </FormatCon>
    </Fragment>
  );
};

export default FormatStyle;
