import styled from "styled-components";

export const FutureCon = styled.div `
  width: 100%;
  display: flex;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 32rem;
    object-fit: cover;
  }
  h3 {
    color: #fff;
    font-size: 1.9rem;
    letter-spacing: normal;
    font-weight: 700;
    padding-bottom: 2rem;
    line-height: normal;
  }
  p {
    font-size: 1.25rem;
    color:#fff;
    line-height: 24px;
    text-align: justify;
  }
  .veil {
    width: 40%;
    height: 70%;
    background: linear-gradient(
      196.38deg,
      #000000 -19.14%,
      rgba(0, 0, 0, 0.88208) 23.01%,
      rgba(0, 0, 0, 0.752962) 73.52%,
      rgba(0, 0, 0, 0) 88.64%
    );
    position: absolute;
    top: 15%;
    padding: 3rem 3rem;
     @media screen and (max-width:979px){
    padding: 2rem;
     width: 70%;

  }
  }
`;