 import styled from "styled-components";

 export const TrendingContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
 export const TrendingWrapper = styled.div `
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 70%;

  @media screen and (max-width: 979px) {
    flex-direction: column;
    align-items: center;
    width: 80%;

 
    .headingCon {
      width: 100%;
      margin:0;
      padding: 1.2em 0.7em;
    }
   
    
    img {
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    display: block;
    object-fit: fill;
    height: auto;
  }
 
  .headingCon {
    width: 100%;
    margin: 0.2em 0.7em;
  }
`;
 export const HeaderInfo = styled.div `
    width: 40%;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;

@media screen and (max-width:670px){
   width: 100%;
      padding:1rem 2rem;
      background-color: #f2f2f2;
    

}

`
 export const ImageContainer = styled.div `

    width: 60%;
    padding: 0;
  
    @media screen and (max-width:967px){
      width:100%
    }


`
 export const MainStoryCon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  padding-bottom: 6rem;
  background-color: #f7f7f7;
`;
 export const StoryCardWrapper = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  width: 70%;
  @media screen and (max-width: 979px) {
    grid-template-columns: 1fr, 1fr;
    gap: 2rem;
    width: 80%;
  }
  @media screen and (max-width: 679px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

 export const CardWrapper = styled.div `
  width: 100%;
  min-height: 14rem;
  background-color: white;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .imageCardCon {
    min-width: 8rem;
    max-width: 100%;
    min-height: 7rem;
  }
  img {
    width: 100%;
    display: block;
    object-fit: fill;
    max-height: 19rem;
  }
  .detailCardCon {
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0.9rem 1.2rem;
  }
  .newsTitle {
    padding-top: 1rem;
    min-width: 10rem;
    text-decoration: none;
    text-align: left;
    transition: all 0.3s ease;
    position: relative;
    color: #424242;
    text-align: left;
    font-weight: 700;
    cursor: pointer;
  }
  .newsTitle:hover {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: black;
    text-decoration: underline;
  }
`;

 export const InfoBlack = styled.h3 `
 display:block;
 width:100%

`