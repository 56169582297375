export const PROJECT_DUMMY = [{
        id: "prc001a",
        header: "Open Source CNC PCB Milling Machine",
        img: " https://drive.google.com/uc?export=view&id=1InLqJq0DHCv9XCEr1gvWL-67ixNDcR67",
        desc: "The PCB milling process is subtractive way of producing electronics boards. material is removed to create the electrical isolation and ground planes required by controlling it through a computer. However, unlike the chemical etch process, PCB milling is typically a non-chemical process and as such it can be completed in a typical office or lab environment without exposure to hazardous chemicals.",
        maker: "Fasil Tariku of Bahir Dar Institute of Technology, BiTec-Seifu BiT-Makerspace / Ethiopia",
        linkedin: " http://www.linkedin.com/in/Fasil-Tariku-Wondafrash1606",
        mail: "fata41389@gmail.com",
        bannerImg: 'https://uploads-ssl.webflow.com/608724ee770d4e59c515dc5f/62ed3a9ea4f21f08a4aa139b_IMG_20220315_085359__01.jpg'
    },
    {
        id: 'prc002b',
        header: 'Jamii Tech Incubation Incubation Program',
        img: 'https://drive.google.com/uc?export=view&id=1Y0vWEoFWznIzfr66_wkkMUCt3iMKCCUH',
        desc: "Jamii Tech Incubation is a 8-12 week design program that connects international teams to do context-driven design with & for members of the Tanzanian community.Teams consist of our very own Tanzanian community partners, designers, international designers, and engineering students. The teams collaborates over a solution to a problem identified by the community partner. Teams are supported by Twende and other mentors, who guide them through a   semi scaffolded process, beginning with understanding the needs of their community partners and ending with an implementable and adaptable solution.",
        desc2: "The program seeks to develop the competence and confidence of all involved, specifically, in the empathetic design process, creative problem solving, local production, cross-cultural collaboration, and ethnographic methods.",
        maker: " Twende Social Innovation Center / Tanzania",
        facebook: "https://www.facebook.com/twendetz?mibextid=ZbWKwL",
        Instagram: "https://www.instagram.com/twendeinnovates/",
        phone: "+255 762622602",
        mail: 'info@twende-tanzania.org',
        bannerImg: 'https://d-lab.mit.edu/sites/default/files/2021-03/DSC_0070_0.JPG'

    },
    {
        id: 'prc003c',
        header: "Solar Mobile Phone Charge Station",
        img: 'https://borgenproject.org/wp-content/uploads/40931254632_dfd4eb5de3_c-e1568833128165.jpg',
        desc: "This is a stand alone solar powered system that provides off grid electricity to charge mobile phones in rural areas.",
        maker: "Noni Hub Makerspace / Ghana",
        mail: 'mustapha@nonihub.org',
        bannerImg: "https://daanigh.com/wp-content/uploads/2022/07/nonihub.jpg",
    },
    {
        id: 'prc004d',
        header: "Automatic H20 Dispenser in Rhino camp refugee settlement",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSHFiRe2zJot75QXL8eqIZ6ik1IauERO51QRx-SiamxGqqMMB-T6ub8U3CIXTD69tbBbY&usqp=CAU",
        desc: " In 2020, when the Covid 19 broke out, the rate of infections was low in the refugee settlement but that didn't stop me from thinking about a cheap solution to reduce the spread of the virus. I researched about the transmission links and found out ,’ contact ‘ was the main factor for the covid 19 spreading in the refugee settlement hence I immediately thought about the schools with over 1700 students/ pupils in the refugee settlements that accesse water from the school taps and learned their is so much contact ' opening the tap and again closing- at this stages we pick up the virus from the taphandle'",
        maker: 'Community Creativity For Development (CC4D-Uganda)',
        mail: 'mathew@cc4duganda.org',
        phone: '+256773525037',
        bannerImg: 'https://d-lab.mit.edu/sites/default/files/styles/large/public/2022-03/Uganda-CCB-March-1.jpg?itok=_sN2Lofc',
        web: 'https://wikifactory.com/+criticalmaking/stories/automatic-h20-dispenser-in-rhino-camp-refugee-settlement'

    },
    {
        id: 'prc005e',
        header: "Mobile Solar Charger",
        img: 'https://drive.google.com/uc?export=view&id=1Xt-IgZ_6o4L-KvBUjUamxpzsGhV85d2u',
        desc: 'Rhino Camp is a home to 115,000 refugees, with 62,926 women. On the refugees’ arrival to Rhino Camp refugee settlement, UNHCR distributed SunBell Solar Lanterns – solar LED lights with a phone charger.',
        desc2: 'But these lights have disappeared (not functioning) and residents are forced to travel almost 90 km to Arua for energy services and products. Though there are some paid charging centers in the settlement, still they charge the devices on a first come first serve basis and women get affected the most because of their tied schedules due to household chores and some women cannot afford the charging fee to charge their phones.',
        desc3: 'In order to reduce the above challenge, I came up with the idea of building a Do It Yourself (DIY) solar mobile phone charger which is a locally made solar charger using the available materials to help charge mobile phones in the rural areas with no electricity like refugee settlements. And this project is aiming at training the women in my community on how to build their own solar mobile phone chargers to keep them connected to their loved ones through their phones and also to save them from travel long distances in order to charge their mobile phones and I have a plan of having women enterprise where we will build rental mobile phone chargers that can be put on charge to Own which will help in sustaining the project.',
        desc4: 'A DIY Solar mobile phone charger is conveniently anywhere, durable and portable. ',
        maker: 'Community Creativity For Development (CC4D-Uganda)',
        web: 'https://wikifactory.com/+criticalmaking/stories/solar-mobile-phone-charger',
        mail: 'info@cc4duganda.org ',
        bannerImg: 'https://d-lab.mit.edu/sites/default/files/styles/large/public/2022-03/Uganda-CCB-March-1.jpg?itok=_sN2Lofc',

    },
    {
        id: 'prc006f',
        header: 'An Arduino-based microcentrifuge',
        video: 'https://drive.google.com/uc?export=view&id=1Vh28o9x20ZD6IAS6OoPU5-BZCOr_BjPS',
        img: 'https://drive.google.com/uc?export=view&id=1LHq2Y2D34yuAhFtBDt763cCANkyRMWpS',
        desc: `In order to provide health clinics in rural and isolated locations with access to a machine that can separate blood fluids and other liquids for accurate testing and diagnosis, we have constructed a microcentrifuge as a first prototype. They won't be able to send these samples to  hospitals in cities that are typically far from them as a result of this. Due to the poor state of the roads in their path, the test tubes containing the samples regularly break.`,
        desc2: `The device will lower the device's cost from $1400 to $50, making it more accessible and perhaps saving countless lives.`,
        maker: "Richard Holdbrook (Ghana Tech Makerspace)",
        mail: 'richardholdbrook2021@gmail.com / ',
        linkedin: 'https://www.linkedin.com/in/richard-holdbrook-9856081a9/',
        bannerImg: 'https://www.ghanatechlab.com/img/minified/arduino/arduino4-min.jpg'
    },
    {
        id: 'qtb326k',
        header: 'An Arduino-compatible motor driver',
        img: 'https://drive.google.com/uc?export=view&id=1FOsccHFW-wN4ejK4wVs94fJzGgxh5H6c',
        desc: `An Arduino-compatible motor driver board for three unipolar stepper motors. Version 0.3`,
        maker: " Eng. Valerian Linux Sanga",
        linkedin: 'https://www.linkedin.com/in/sangavalerian/',
        bannerImg: 'https://drive.google.com/uc?export=view&id=1FOsccHFW-wN4ejK4wVs94fJzGgxh5H6c',
        web: 'https://kitspace.org/boards/gitlab.com/bath_open_instrumentation_group/sangaboard/sangaboard_v0.3/'
    }

]