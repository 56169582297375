import { Fragment } from "react";
import {  GridPartnerWrapper } from "./GridPartners.style";
import { PartnerImgCon ,PartnerImg} from "../Partners/Partners.style";



const GridParnterList = (props) => {
    return ( 
        <Fragment>
            <GridPartnerWrapper>
            <PartnerImgCon>
                <a href={props.href}>
               <PartnerImg src={props.image} alt={props.name}/>
               </a>
               </PartnerImgCon>  
            </GridPartnerWrapper>
        </Fragment>
     );
}
 
export default GridParnterList;