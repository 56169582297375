import styled from "styled-components";
export const MboaMainSumCon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MboaSumCon = styled.div `
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  align-items: center;
`;

export const MboaSumWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width:967px){
    flex-direction:column;
  }
`;
export const MboaImgCon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media screen and (max-width:967px){
   width:100%
  }
  img {
    display: block;
    object-fit: contain;
    width: 100%;
  }
`;

export const MboaDescCon = styled.div `
  margin-left: 3rem;
  width: 50%;
  @media screen and (max-width:967px){
   width:100%;
   margin-left:0;
  }
  h5 {
    text-align: left;
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    color: #424242;
  }
  p {
    color: #828282;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-top: 1.5rem;
    text-align:left;
  }
  button {
    margin-top:.5rem;
    padding:.5rem 2rem;
    color:white;
    background-color:#000;
    border:none;
    outline:none;
    border-radius:4px;
  }
`;