import { Fragment } from "react";
import { ThumCon,  } from "./Community.style";
import { Youtube } from "grommet-icons";
import { Facebook } from "grommet-icons";
import { Linkedin } from "grommet-icons";
import { Mail } from "grommet-icons";
import { Phone } from "grommet-icons";
import { Link, } from "react-router-dom";
import styled from "styled-components";

const StoryLink = styled(Link)`
  

`

const CommunityThumbnail = (props) => {


    const space = <br/>
    return ( 
        <Fragment>
            
            <ThumCon>
              <StoryLink to={`${props.id}`}>
                <div className="disImg">
                    <img src={props.img} alt="mine"/>
                </div>
                </StoryLink>
                <div className="infoCon">
                    <div className="wrapper">
                        <div className="subheader">
                            <span>Maker:</span>
                            <span> {props.subheader}</span>
                        </div>
                        <div className="mainheader">
                            <h3>{ props.mainheader.length > 40 ?  props.mainheader.slice(0,40)+"..." : props.mainheader.length <18 ? props.mainheader+space : props.mainheader }</h3>
                        </div>
                        <hr className="line"/>
                    </div>
                    <div className="tilewrapper">
                   { props.youtube&&
                     <div className="tile">
                     <a href={props.youtube} target="_blank" rel="noopener noreferrer">
                       <Youtube size="small" color="#3B3B3B"/>
                     </a>
                 </div>
                   }
                    { props.phone &&
                     <div className="tile">
                     <a href={'tel:'+props.phone} target="_blank" rel="noopener noreferrer">
                       <Phone size="small" color="#3B3B3B"/>
                     </a>
                 </div>
                   
                   }
                   { props.linkedin &&
                     <div className="tile">
                     <a href={props.linkedin} target="_blank" rel="noopener noreferrer">
                       <Linkedin size="small" color="#3B3B3B"/>
                     </a>
                 </div>
                   
                   }
                   { props.facebook &&
                     <div className="tile">
                     <a href={props.facebook} target="_blank" rel="noopener noreferrer">
                       <Facebook size="small" color="#3B3B3B"/>
                     </a>
                 </div>
                   
                   }
                   { props.mail &&
                     <div className="tile">
                     <a href={'mailto:'+props.mail} target="_blank" rel="noopener noreferrer">
                       <Mail size="small" color="#3B3B3B"/>
                     </a>
                 </div>
                   
                   }
                         
                    </div>
                </div>

            </ThumCon>
          
        </Fragment>
     );
}
 
export default CommunityThumbnail;