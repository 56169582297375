import { useState, useCallback } from 'react';

const useHttp = () => {
    const [sent, setIsSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async(requestConfig, applyData) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(requestConfig.url, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: requestConfig.headers ? requestConfig.headers : {},
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
            });

            if (!response.ok) {
                throw new Error('Request failed!');

            }



            const data = await response.json();
            applyData(data);
        } catch (err) {
            setError(err.message || 'Something went wrong!');
        }
        setTimeout(() => {
            setIsSent(true)
            clearTimeout()
        }, 3000);
        setIsSent(false)
        setIsLoading(false);
    }, []);

    return {
        sent,
        isLoading,
        error,
        sendRequest,
    };
};

export default useHttp;