import { Fragment } from "react";
import { MboaPageCon,MboaPageWrapper } from "./Mboalab.style";
import imagebg from "../../assets/images/mblbg.png"
import FooterOsh from "../../components/FooterOsh/FooterOsh";

const MboaLab = (props) => {
    return ( 
        <Fragment>
            <MboaPageCon>
                <MboaPageWrapper>
                <h5 className="markdown">about mboalab</h5>
            <h2>All you need to know of MboaLab</h2>
            <img src={imagebg} alt="MboaLab" />
            <p>
            <a rel="noreferrer" target="_blank" href="https://website-mboalab.vercel.app/">MboaLab</a> is an open and collaborative space located in Yaoundé,
                Cameroon. It is a laboratory for social innovation, community
                education, collaboration and mediation at the service of the
                common good. Operating as a research and applied Center for Open
                Science and Citizen Science, Mboalab was officially opened in
                December 2017. The objective is to catalyze sustainable local
                development and improve people’s living conditions through open
                science. By choosing education and health as our priority axes
                of intervention, in addition to its role as a mediator between
                Science-Technologies-Societies, we can say that Mboalab is a
                powerful catalyst for local sustainable development.
              </p>
              <p>
                As a community innovation hub, Mboalab also promotes sustainable
                industrialization through the popularization and adoption of new
                digital technologies and by lowering the barriers to equipment
                access, creating a small-scale manufacturing mindset for the
                local population for the production of products and services
                needed in the community.
              </p>
              <p>
                Main activities at Mboalab are centered around capacity building
                for young scientists and local talents to develop researchers
                and local innovators particularly women in STEM in the field of
                Molecular and Synthetic Biology, Engineering and Data Science
                who can apply state-of-the-art technologies to achieve
                sustainable development goals within their own context and build
                enterprises that will generate employment. This addresses SDG9,
                “Industry, innovation and infrastructure” and SDG8, “Decent work
                and economic growth”.
              </p>
              <p>
                Aiming to catalyse local sustainable development and improve
                people’s living conditions through open science. Mboalab
                activities remained constant over the years. Since Its creation
                in 2018 after setting up existing infrastructures now used to
                support the various activities which continue to this day. With
                these few facilities, Mboalab was able to perform the following
                actions: The library offers the public the possibility to
                consult the books available on site. It is also equipped with a
                few low-cost computers of the Raspberry Pi;
              </p>
              <p>
                The Fablab has made a low-cost incubator that allows women to
                make traditional yoghurt (Kossam) following good hygiene
                practices and standards; and is now working at locally
                prototyping open-source devices, integrating the latest digital
                technologies (3D printing, Machine learning) for application in
                enzyme manufacturing, medical diagnostic and tailor-made for
                resource-constrained environments.
              </p>
              <p>
                The synthetic biology unit has been very active in the field of
                local enzyme biomanufacturing, organizing training and
                workshops, participating in international and multidisciplinary
                scientific research projects, and developing novel protocols and
                techniques Including for diagnosing SARS Cov2 Virus and endemic
                infectious diseases (malaria, cholera, and typhoid fever).
              </p>
              <p>
                Since September 2019, Mboalab has also been recognized as a
                Peacebuilding Centre by the German Foreign Office. Indeed, given
                the Anglophone crisis that has prevailed in Cameroon since 2016,
                the Mboalab also carry out peacebuilding activities to a place
                where Anglophones and Francophones interact and collaborate in
                harmony and peace.
              </p>
                </MboaPageWrapper>
            </MboaPageCon>
            <FooterOsh/>
        </Fragment>
     );
}
 
export default MboaLab;