import styled from "styled-components";
export const OuterShell = styled.div `

height: 80vh;


`
export const CarouselCon = styled.div `
position:absolute;
top:38%;
left:15%;
color:white;
z-index: 99;
padding-bottom: 1rem;

 h2{
    color:white;
    font-weight:bold;
    padding-bottom:1rem;
    font-size:2rem;
 }

p{ 
  
  font-size: 20px;
  font-weight:normal;
  z-index:99;
  color:white;
}
`