import { Fragment } from "react";
import {
  IdeologyContainer,
  IdeologyWrapper,
  BgTextCon,
  Texth3,
  
  Shadow
} from "../Ideology/Ideology.style"; 
import { Grommet } from "grommet";

const Ideology = (props) => {
  return (
    <Fragment>
      <Grommet background="light-2">
          <Shadow>
        <IdeologyContainer>
          <IdeologyWrapper>
            <BgTextCon>
              <Texth3 className="main-header">
                As part of the Gathering for Open Science Hardware (GOSH) community, We are committed to making open science hardware ubiquitous by 2025
              </Texth3>
              
            </BgTextCon>
          </IdeologyWrapper>
        </IdeologyContainer>
        </Shadow>
      </Grommet>
    </Fragment>
  );
};

export default Ideology;
