import styled from 'styled-components';

export const MainSummitWrapper = styled.div `
width:90%;
display:flex;
flex-direction:column;
align-items:center;
@media screen and (max-width:920px){
    width:100%;
   
}
`
export const Line = styled.div `
color:red;
width: 100%;


`