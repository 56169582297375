import styled from "@emotion/styled";

export const EventHeaderCon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ProgramsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
`;

export const ProgramBannerCon = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;
export const ProgramBannerImg = styled.img `
  width: 100%;
  object-fit: content;
  height: 100%;
`;
export const ProgramBlock = styled.div `
  width: 47%;
  height: inherit;
  position: absolute;
  left: 53%;
  top: 0%;
  background: linear-gradient(
    196.38deg,
    #000000 -19.14%,
    rgba(0, 0, 0, 0.88208) 23.01%,
    rgba(0, 0, 0, 0.752962) 73.52%,
    rgba(0, 0, 0, 0) 88.64%
  );

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;

    width: 100%;
    padding: 3rem;
  }
  h3 {
    color: white;
    display: inline-block;
    font-size: 2.1vw;
  }
`;
export const EventMargin = styled.div `
  margin: 1rem;
  padding-top: 1rem;
`;
export const EventListCon = styled.div `
  border-top: 1px solid #1f1f1f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  width: 100%;
  margin-bottom: 1.4rem;
`;

export const EventListWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.6em;
  width: inherit;
`;
export const EventListImgCon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
`;
export const EventListImg = styled.img `
  width: 100%;
  object-fit: contain;
  height: auto;
`;

export const EventListDescCon = styled.div `
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 1rem;

  .categoryBox {
    background-color: #1f1f1f;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-size: 0.9rem;
    width: fit-content;
    font-weight: bold;
    padding: 0.3em 0.5em;
    margin-bottom: 0.5em;
  }

  .themeBox {
    letter-spacing: normal;
    color: #424242;
    font-weight: bold;
    font-style: normal;
    font-size: 1.35rem;
  }
  .descBox {
    color: #5b6278;
    font-weight: normal;
    font-size: 1.25rem;
    letter-spacing: normal;
  }

  ul,
  li {
    font-family: 'Karla', sans-serif;
  }
  ul {
    display: flex;
    margin-top: 0.3rem;
    margin-left: 0.9rem;
    flex-direction: column;
  }
  .authorBox,
  .locationBox {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 0.85em;
    color: #828282;
  }
  .btnLink {
    color: white;
    outline: none;
    border: none;
    background-color: black;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
  }
  a {
    text-decoration: none;
  }
`;