import { AchievementCon ,WhiteText,ShootingNumber } from "./ParralaxHome.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment,useState,useEffect , useRef} from "react";




const AchievementBox = (props) => {
  const icons = require("@fortawesome/free-solid-svg-icons");

  const [shooting, setShooting] = useState("0");

useEffect(() => {
  const abortController = new AbortController()
  const observer = new IntersectionObserver((entries,observer) => {
    const entry = entries[0];
    // console.log('entry', entry);
    // console.log('entry.isIntersecting', entry.isIntersecting);
  
 
  if(entry.isIntersecting){
    let start  = 0;
    let end  = parseInt(props.num);
    if(start === end) return;


    let totalDuration = parseInt(props.duration);
    let incrementTime = (totalDuration / end) * 1000;

   let timer =  setInterval(() => {
     start +=1
    //  if(parseInt(props.num) > 100) {
    //     setShooting( start +  props.num.substring(3))
    //   } else if(parseInt(props.num) < 100) {
    //     setShooting(start + props.num.setShooting(2))
    //   }

    setShooting( start +  props.num.substring(3))



        if(start === end ) {
          clearInterval(timer)
         }
    }, incrementTime);
  }
});
observer.observe(myRef.current);
return () => {
  abortController.abort()
  // stop the query by aborting on the AbortController on unmount
}
}, [props.num,props.duration])

const myRef = useRef();

  return (
    <Fragment>
      <AchievementCon >
          <div className="space" ref={myRef}>
        <FontAwesomeIcon icon={icons[props.icon]} size="3x"/>
        </div>
        <WhiteText>{props.title}</WhiteText>
        <ShootingNumber>{shooting}{ props.plus ? props.plus : ""}</ShootingNumber>
      </AchievementCon>
    </Fragment>
  );
};

export default AchievementBox;
