import { Fragment } from "react";
import OurTeamList from "./OurTeamList";
import { MainAboutInfoCon } from "../AboutHeader/AboutHeader.style";
import {SmallHeader, SmallHeaderCon,OurTeamCon, OurTeamConWrapper } from "./OurTeam.styled";

const DUMMY_EXECUTIVEDATA = [
  
  {name:"Jorge Appiah",role:"Executive Director",linkedin:"https://www.linkedin.com/in/george-kwadwo-appiah-27634131/",mail:"jorgeappiah@kumasihive.com",profileImage:"https://media.licdn.com/dms/image/C5603AQGsCgoLyZsD_g/profile-displayphoto-shrink_800_800/0/1516472802786?e=1680739200&v=beta&t=CPhHHhFgknh2czn76swwOmsTWVvTLAE3h3oSW4bdlFE"},
  {name:"Thomas H. M. Nkoudou",role:"Executive Director",linkedin:"https://www.linkedin.com/in/thomas-herv%C3%A9-mboa-nkoudou-phd-522b7a42/?originalSubdomain=cm",mail:"thomasmboa@gmail.com",profileImage:"https://media.licdn.com/dms/image/C4E03AQH-6D9zM9LOBQ/profile-displayphoto-shrink_800_800/0/1605906484015?e=1680739200&v=beta&t=Y6hGokfIAsbMuptkqJMJIpU2U-1X942JJevpXljI1QU"},
  {name:"Eng.Valerian Sanga",role:"Executive Board Member",linkedin:"https://https://www.linkedin.com/in/sangavalerian/" ,mail:"sangavalerian@live.com ",profileImage:"https://media.licdn.com/dms/image/C5603AQF_rHjHPX_fYQ/profile-displayphoto-shrink_800_800/0/1516861648133?e=1680739200&v=beta&t=FBW3z44p5v7Nif5vZi7e6djO0AZ3mJ7NUeyvUMxOWd8"},
  {name:"Frank Bentum",role:"Executive Manager",linkedin:"https://www.linkedin.com/in/franklandonbentum/",mail:"frank@africaosh.com",profileImage:"https://media.licdn.com/dms/image/D4E03AQG4EZsOKhMrPQ/profile-displayphoto-shrink_800_800/0/1664452207117?e=1680739200&v=beta&t=UT72Ib7DA4QoUCg-KvAf0kfv6QQwac9-wk_20MfSMW8"},
  // {name:"Maurice Caschinco",role:"Advisory Board Member",linkedin:"https://www.linkedin.com/in/maurice-houston-caschinco-002526101/",mail:"maurice@kumasihive.com",profileImage:"https://media-exp1.licdn.com/dms/image/C4E03AQHMHKOEArDUrQ/profile-displayphoto-shrink_800_800/0/1594903217553?e=1654732800&v=beta&t=nxeMSHOABuC8gc94HHXFE4ZFuDFdbRcV7IoV36LadiA"},
 

]
const DUMMY_OPERATIONSDATA = [
  {name:"Freda Yamorti Gbande",role:"Technical Advisor",linkedin:"https://www.linkedin.com/search/results/all/?keywords=freda%20yamorti%20gbande&origin=RICH_QUERY_SUGGESTION&position=0&searchId=a1de8593-67f8-4a48-924f-a22a44237526&sid=nLD",mail:"freda@africaosh.com",profileImage:"https://media.licdn.com/dms/image/C4E03AQGP_AbQYIvabw/profile-displayphoto-shrink_800_800/0/1656677505608?e=1680739200&v=beta&t=sXSnG5wED-ftpiRL71wcNGDvLIU_96GjU9SbSU95JRA"},
  {name:"Rita Baiden",role:"Finance Associate",linkedin:"https://www.linkedin.com/in/rita-baiden-722529155/", mail:"rita@kumasihive.com",profileImage:"https://media.licdn.com/dms/image/C4D03AQHtoeg8cRJ78A/profile-displayphoto-shrink_800_800/0/1615326719471?e=1680739200&v=beta&t=YL4csRkJ04eysT1ny0cfLqsKfcPuR97OMxUHjv0laUo"},
  {name:"David Amfo-Kwakye",role:"Media Associate",linkedin:"https://www.linkedin.com/in/david-amfo-kwakye-7798b298/",mail:"david@kumasihive.com",profileImage:"https://media.licdn.com/dms/image/C4D03AQHYK-AXqG3JVw/profile-displayphoto-shrink_800_800/0/1588775132937?e=1680739200&v=beta&t=oc9d43_TeoaZ8nmY3zLkwcd6-uBwu-ttJ8Pi87jlDBo"},
  {name:"Bernardo Acheampong",role:"Software Developer",linkedin:"https://www.linkedin.com/in/bernardo-acheampong-692145195/",mail:"ben@kumasihive.com",profileImage:"https://media.licdn.com/dms/image/C4E03AQFKF0oFyc0Qow/profile-displayphoto-shrink_400_400/0/1662286877610?e=1680739200&v=beta&t=QYHv6RozrAYR3ToBu6eTZvUQhsORSR5JUwU84MNBaxo"},
]

const OurTeamOsh = (props) => {
  return (
    <Fragment>
      <OurTeamCon>
          <MainAboutInfoCon >
            <h3 className="blackText main-header">Meet our team</h3>
          </MainAboutInfoCon>
          <SmallHeaderCon>
                    <SmallHeader style={{marginTop:"1rem"}}>{"Our Executive board"}</SmallHeader>
                </SmallHeaderCon>
          <OurTeamConWrapper>
       
            {DUMMY_EXECUTIVEDATA.map((index,key) => {
              return  <OurTeamList listText={"Our text"} memberLinkedin={index.linkedin} memberName={index.name} key={key} memberRole={index.role} memberEmail={index.mail} memberImg={index.profileImage}/>

            })}
        </OurTeamConWrapper>
        <SmallHeaderCon>
                    <SmallHeader style={{marginTop:"1rem"}}>{"Our operations team"}</SmallHeader>
                </SmallHeaderCon>
          <OurTeamConWrapper>
       
            {DUMMY_OPERATIONSDATA.map((index,key) => {
              return  <OurTeamList listText={"Our text"} memberLinkedin={index.linkedin} memberName={index.name} key={key} memberRole={index.role} memberEmail={index.mail} memberImg={index.profileImage}/>

            })}
        </OurTeamConWrapper>
      </OurTeamCon>
    </Fragment>
  );
};

export default OurTeamOsh;
