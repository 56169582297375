import styled from "styled-components";
import bgBuild from "../../assets/images/bg-build.png"
export const IdeologyWrapper = styled.div `
display:flex;
width: 100%;
max-width: 52rem;
flex-direction: column;

`
export const IdeologyContainer = styled.div `
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
background-repeat:no-repeat;
background-image:url(${bgBuild});

@media screen and (max-width:467px){
   background-image:none;
}
`
export const BgTextCon = styled.div `
margin:2rem;
padding: 1rem 3rem;

`
export const Texth3 = styled.p `
text-align:center;
font-style:italic;
margin: auto 0;
line-height:2.5rem;
`
export const Button = styled.button `
padding:.63rem 2.7rem;
text-transform: uppercase;
background-color: black;
color:white;
font-weight:900;
outline:none;
letter-spacing:.2em;
text-decoration:none;
border:none;
border-radius:3px;
font-size:.9rem;
font-weight:bold;

a{
    color:#fff;
    text-decoration:none;
    
    
}
`
export const ButtonCon = styled.div `
display:flex;
justify-content:flex-start;
align-items:flex-start;
margin-top:2rem;
`
export const Shadow = styled.div `
background: url('../../assets/images/BongoTech.png');
background-position:center;
`