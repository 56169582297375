import { Button,ButtomCon,SummitsConatainer,SummitWrapper,SummitImageCon,SummitImage,SummitInfoCon,SummitHeaderTextCon } from "./SummitsSummary.style"
import {
    NewsDate,
    NewsDateCon,
    NewsHeaderTextCon,
  } from "../News/News.style";
  import React,{useState} from "react"
import { Link } from "react-router-dom";


const ListSummit = (props) => {
const [click, setClick] = useState(false)
    return ( 

        <SummitsConatainer>
    <SummitWrapper>
      <SummitImageCon>
        <SummitImage alt="Hello" src={props.ViewImage}></SummitImage>
      
      </SummitImageCon>
      <SummitInfoCon>

        <NewsHeaderTextCon>
        <NewsDate>{props.date}</NewsDate>
          <h5 className="summit-header">
            {props.summitHeader}
          </h5>
         
        </NewsHeaderTextCon>

        <SummitHeaderTextCon>
          <p className="Wtext-paragraph">
          {props.summitDesc}
          </p>
        </SummitHeaderTextCon>

        <NewsDateCon>
          <NewsDate>{props.hostVenue}</NewsDate>
         
        </NewsDateCon>
        <ButtomCon>
        <Button   style={props.downloadable ? {display:'flex'} : {display:'none'}} onClick={props.onClick}> 
        <a id={props.dlink} href={props.pdf} onClick={()=>setClick(true)}>{click ? 'Download' : 'Get Report'}</a>
        </Button>
        <Button style={props.read ? {display:'block'} : {display:'none'}} onClick={props.onClick}>
         { props.Link &&   <Link to={props.Link} style={{fontWeight:600}}>READ  MORE</Link>}
        </Button>
        </ButtomCon>
     
      </SummitInfoCon>

    </SummitWrapper>
  </SummitsConatainer>
 
     );
}
 
export default ListSummit;