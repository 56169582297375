import styled from "styled-components";


export const AboutCon = styled.div `
width:100%;
display:flex;
align-items:center;
justify-content:center;
margin-bottom:2em;


`

export const AboutWrapper = styled.div `
width: 60%;
background-image: url("../../assets/images/bg-build.png");
@media screen and (max-width:920px){
  width:100%  
}
@media screen and (max-width:920px) {
  padding:1.5em;
}
`