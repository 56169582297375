import styled from "styled-components";

export const FormatCon = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
 
`;

export const FormatWrapper = styled.div `
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 1 1 200px;
  width: 70%;
  color: #424242;
  margin-bottom:7rem;
@media screen and (max-width:967px){
  flex-direction:column;
}
  p {
    font-size: 1.7rem;
  }
  .Presentations , .Workshops,.Unconference {
    letter-spacing: 0.25rem;
    color: #424242;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 6rem;
    
  }

  .Workshops {
    border-left: 2px solid gainsboro;
    border-right: 2px solid gainsboro;
    @media screen and (max-width:967px){
      border-left:none;
      border-right:none;
    }
  }
`;

export const TitleCon = styled.div `

  margin: 1.5rem;
`;
export const Gline = styled.hr ` 
display:none;
@media screen and (max-width:967px){
color:gainsboro;
display:block;
height:8rem;
}
`