import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  MantraHeader,
  ItemMantraWrapper,
  MainMantraCon,
  Description,
  FontBox,
} from "./Mantra.styled";
import { Fragment } from "react";

const MantraItem = (props) => {
  const icons = require("@fortawesome/free-solid-svg-icons");

  return (
    <Fragment>
      <MainMantraCon>
        <ItemMantraWrapper>
          <FontBox>
            <FontAwesomeIcon icon={icons[props.symbol]} />
          </FontBox>
          <MantraHeader className="Wtext-header">
            {props.mantraHeader}
          </MantraHeader>
          <Description className="Wtext-paragraph">
            {props.mantraText}
          </Description>
        </ItemMantraWrapper>
      </MainMantraCon>
    </Fragment>
  );
};

export default MantraItem;
