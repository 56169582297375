import styled from "styled-components";
import sumOshImg from "../../assets/images/cameMap.png";
export const SMPHeaderCon = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media screen and(max-width:967px){
    justify-content:space-around;
  }
`;

export const SMPHeaderWrapper = styled.div `
  display: flex;
  align-self: center;
 @media screen and (max-width:967px){
  flex-direction:column;
 }
`;

export const SMPHInfoCon = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left:15%;
  width:45%;
  @media screen and (max-width:967px){
    width:100%;
    margin-left:0;
    padding-left:15%;
    padding-right:15%;
    align-items:center;

  }
  .oshTitle {
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
    color: #424242;
    width:70%;
    @media screen and (max-width:967px){
      width:100%;
    }

  }
  .sumPara {
    color: #828282;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: left;
    margin-top:1.5rem;
    margin-bottom:7rem;
  }
`;
export const SMPHImgCon = styled.div `
  background-position: center;
  background-size: 100%;

  
`;

export const SMPMainImgCon = styled.div ` 
width:55%;
background-image: url(${sumOshImg});
background-position:fill;
background-size:100%;
background-repeat:no-repeat;
@media screen and (max-width:967px){
    width:100%;
    height:40rem;
    background-repeat:no-repeat;
  }
  @media screen and (min-width:1200px){
    padding:21rem;

  }
`