import styled from 'styled-components';

export const SummitsConatainer = styled.div `
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:70%;
  margin-bottom:3rem;
  padding:1rem;


`;

export const ButtomCon = styled.div `
padding:1rem;
margin-top:1rem;
width:100%;
padding-left:0;
`
export const Button = styled.button `
background-color:black;
border-radius:2px;
border:none;
padding: .4em 1.2em;
color:white;
letter-spacing:.2em;
transition: all .4s ease-in;
outline:none;
cursor: pointer;
text-decoration:none;

a{
  text-decoration:none;
  color:white;
  &:hover{
    color:white;
    text-decoration:none;
  }
  
}
/* &:hover{
  scale: .9;
} */
`

export const SummitWrapper = styled.div `
display: flex;
align-items:center;
justify-content:center;
background-color: #F2F2F2;

@media screen and (max-width:920px) {
  flex-direction:column;
}
`
export const SummitImageCon = styled.div `
display:flex;
width:50%;
justify-content:center;
margin-right: 1.3rem;
@media screen and (max-width:920px) {
  width:100%;
  margin:0;
}
`
export const SummitInfoCon = styled.div `
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:50%;
@media screen and (max-width:920px) {
 width:100%;
 padding:1rem;
}
`
export const SummitImage = styled.img `
object-fit:center;
width:100%;
height:100%;

`

export const SummitHeaderTextCon = styled.div `

`

export const SummitHeaderText = styled.p `
font-style: normal;
font-weight: 400;
font-size: 20px;


`

export const LinkButton = styled.button ` 
background-color:black;
border:none;
outline:none;
padding:.5rem 1.2rem;
border-radius:2px;

a{
  font-size:1.2rem;
  text-decoration:none;
  text-transform:uppercase;
}
`