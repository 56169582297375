import { Fragment } from "react";
import EventList from "./EventList";
import igem from "../../assets/images/IGEM.jpg"
import arxiv from "../../assets/images/arxiv.jpg"
import BannerEventImg from "../../assets/images/EventBanner.png";
import { MainAboutInfoCon, ShortDesc } from "../AboutHeader/AboutHeader.style";
import EvntImg from "../../assets/images/thumbnail.jpg";
import EvntRole from "../../assets/images/fff.jpg"
// import defaultImg from "../../assets/images/osh.png"
import Parser from "html-react-parser";
import {
  EventMargin,
  EventHeaderCon,
  ProgramsWrapper,
  ProgramBannerCon,
  ProgramBannerImg,
  ProgramBlock,
} from "./EventHeader.style";
const DUMMY_EVENTS = [

  { 
    category:"AfricaOSH | AfricArXiv Webinar",
    video:true,
    videoLink:"https://www.youtube.com/embed/KGNabpO69C8",
    image:`${arxiv}`,
   date: "11 August 2022",
    host:"AfricArXiv",
    speaker:"",
    theme: "Open Scholarly Publishing, Preprints, and Peer review.",
    desc:
      "Join us on a webinar with AfricArxiV as we explore the world of open access and open research. AfricaOSH will host AfricArXiv, a community-led digital archive for African research, working towards building an African-owned open scholarly repository; a knowledge commons of African scholarly works to catalyze the African Renaissance to discuss open scholarly publishing, preprints and peer review..",
  },
  { 
    category:"AfricaOSH | IGEM Webinar",
    video:true,
    videoLink:"https://video.igem.org/videos/embed/5f4ba800-4c7a-4097-8430-3e0de1e1be36",
    image:`${igem}`,
   date: "22nd July, 2022",
    host:"",
    speaker:"",
    // register:"https://surveymonkey.com/r/IGEM-AFRICAOSH",
    theme: "Learn about IGEM & AfricaOsh activities",
    desc:
      "Join us this Friday (July 22nd, 10:00 UTC) in a Webinar about the iGEMCommunity (International Genetically Engineered Machine) and AfricaOSH as we introduce the iGEM Community and its activities to the rest of the African community, and also learn about the work being done by AfricaOSH.",
  },

  { 
    category:"AfricaOSH | GOSH Webinar",
    video:true,
    videoLink:"https://www.youtube.com/embed/bbbDdqNaJsM",
    image:`${EvntImg}`,
   date: " 26th May, 2022",
    host:"Freda Y. Gbande",
    speaker:"Brianna Johns & Abdoul Kafid Toko Koutogui",
    theme: "Introduction to the Gathering for Open Science Hardware (GOSH)",
    desc:
      "A webinar introducing the AfricaOSH Community to the GOSH Community.",
  },

  { 
    category:"AfricaOSH Webinar",
    video:true,
    videoLink:"https://www.youtube.com/embed/MVff69YbKWE",
    image:`${EvntImg}`,
   date: "Thursday, 28th April, 2022",
    host:"Freda Y. Gbande",
    speaker:"Harry Akligoh, Andre Maia Chagas, Gameli Adzaho, Johanna Havemann",
    theme: "Open Science in Africa",
    desc:
      "<ul><li>Open Science in Africa: the importance and benefits of open science in Africa.</li><li>The challenges faced by African open science practitioners and how to meet these challenges.</li><li>The state of the African open science ecosystem during and after the COVID-19 pandemic and what we learnt from it as a community.</li></ul>",
  },
  { 
    category:"AfricaOSH Open Hardware Workshop ( Building an OpenFlexure Microscope from scratch)",
    video:false,
    videoLink:"https://www.youtube.com/embed/MVff69YbKWE",
    image:`${EvntRole}`,
   date: "20 July 2023 // 1:00 pm UTC",
    host:"",
    speaker:"Oluwatobi Oyinlola",
    theme: "AfricaOSH Webinar on Documentation of Open Hardware Projects.",
    desc:
      "<p>AfricaOSH successfully organized its first in-person open science hardware workshop in Ghana on June 16, 2023. The workshop took place at the Kumasi Hive Event space and primarily catered to students from the Kwame Nkrumah University of Science and Technology, with one participant being a high school teacher from the Ghana Education Service (GES). A total of 13 individuals attended the workshop, consisting of 6 females and 9 males.</p>",
  },
  
];
const EventHeader = (props) => {
  return (
    <Fragment>
      
      <EventHeaderCon>
        <MainAboutInfoCon>
          <ShortDesc>AfricaOSH programs</ShortDesc>
          <h3 className="blackText main-header">All Events</h3>
        </MainAboutInfoCon>
      </EventHeaderCon>
      <ProgramsWrapper>
        <ProgramBannerCon>
          <ProgramBannerImg src={BannerEventImg} alt="Event banner" />
          <ProgramBlock>
            <div>
              <h3>Africa Open Science Hardware (AfricaOSH) program list for the first half of the year </h3>
            </div>
          </ProgramBlock>
        </ProgramBannerCon>
        <MainAboutInfoCon>
          <EventMargin>
            <h3 className="blackText main-header">Upcoming Events</h3>
          </EventMargin>
        </MainAboutInfoCon>
        {DUMMY_EVENTS.map((index,key) => {
         return ( <EventList link={index.register} videoLink={index.videoLink} video={index.video} key={key} host={index.host} speakers={index.speaker} date={index.date} image={index.image} description={Parser(index.desc)} theme={index.theme} category={index.category} />)
        })}
      </ProgramsWrapper>
    </Fragment>
  );
};

export default EventHeader;
