import styled from "styled-components";

export const ValueContent = styled.div `
display:flex;
flex-direction:column;
justify-content:stretch;
width:45%;

#visionImg{
   
    margin-top:3%;
}
img {
    width:100%;
    object-fit: cover;
}
@media screen and (max-width:920px){
    width:100%;
}
`