import { Fragment } from "react";
import { FutureCon } from "./FutureLeap.style";
import view from "../../assets/images/view.jpg";
const FutureLeap = (props) => {
  return (
    <Fragment>
      <FutureCon>
        <img src={view} alt="leap into the future" />
        <div className="veil">
          <h3>Our Leap into the future</h3>
          <p>
            The ultimate goal is to achieve an ecosystem for innovation that is
            locally adapted, culturally relevant, economically viable, and
            environmentally sustainable as we create an ecosystem of shared
            knowledge, ideas, and resources to accelerate innovation in Africa.
          </p>
        </div>
      </FutureCon>
    </Fragment>
  );
};

export default FutureLeap;
