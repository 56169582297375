import { Fragment } from "react";
import {
  CommunityPageCon,
  CommunityPic,
  ConPic,
  ThumbFrame,
  WidthCon,
  CommunityWrapper
} from "./Community.style";
import imgInitiatives from "../../assets/images/hotnews.jpg";
import { PROJECT_DUMMY } from "./DATA";
import CommunityThumbnail from "./CommunitThumnail";
import FooterOsh from "../../components/FooterOsh/FooterOsh";

const CommunityPage = (props) => {
  return (
    <Fragment>
      <CommunityWrapper>
      <CommunityPageCon>
        <h6
          style={{ color: "#e2e2e2", textAlign: "center" }}
          className="markdown"
        >
          {"Community Projects & Initiatives"}
        </h6>
        <h3 style={{color:'white'}} className="main-header">
          Get to know all the projects and initiatives happening in the
          AfricaOSH Community by our community members
        </h3>
      </CommunityPageCon>
      <ConPic>
        <CommunityPic>
          <img src={imgInitiatives} alt="project-initiatives" />
        </CommunityPic>
      </ConPic>
      </CommunityWrapper>
      <WidthCon>
        <ThumbFrame>
          {PROJECT_DUMMY.map((index, key) => {
            return (
                <CommunityThumbnail id={index.id} key={key} linkedin={index.linkedin} img={index.img} subheader={index.maker} mainheader={index.header} mail={index.mail} facebook={index.facebook} instagram={index.instagram} phone={index.phone} />
            )
           
          })}
        </ThumbFrame>
      </WidthCon>
      <FooterOsh></FooterOsh>
         </Fragment>
  );
};

export default CommunityPage;
