import styled from 'styled-components';

export const MainConnectCon = styled.div `
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;

@media screen and (max-width:920px){
  margin:0 6rem;  
}
@media screen and (max-width:679px){
   margin:0 4rem;
}


`
export const ConnectCon = styled.div `
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
margin-bottom:2.5rem;



`
export const ConnectWrapper = styled.div `
display:flex;
gap:3em;
flex-direction: ${(props)=> props.reverse === 'yes' ? 'row-reverse' : 'row' };

@media screen and (max-width:920px){
    flex-direction:column;
}
`
export const MainTextCon = styled.div `

width:50%;
@media screen and (max-width:920px) {
    width:100%;
}
`
export const TextParagraphCon = styled.div `



`
export const MainImgCon = styled.div `
width:50%;
@media screen and (max-width:920px){
   width: 100%;
   padding:0; 
}


`
export const Gimg = styled.img `
 width: 100%;
object-fit:contain;



`

export const MainTextWrapper = styled.div `
text-align:left;
width:80%;

@media screen and(min-width:1024){
    width:100%;
}
`