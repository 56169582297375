import logo from "../../assets/images/aosh.png";
import classes from "./MainNav.module.css";
import { NavLink} from "react-router-dom";
import {useState, useEffect} from "react";
import { Close } from 'grommet-icons';
import { Menu as HamMenu} from 'grommet-icons';

 


const MainNav = (props) => {
const [hamburger, setHamburger] = useState(false);
const [width, setWindowWidth] = useState(0);



  useEffect(() => { 

    updateDimensions();
  

    window.addEventListener("resize", updateDimensions);  
       return () => 
      window.removeEventListener("resize",updateDimensions);  
    
       
     }, [])

const updateDimensions = () => {
  const width = window.innerWidth
  setWindowWidth(width)
}

    // const theme = {
    //     global: {
    //         colors: {
    //             control: '#E0E0E0',
    //             brand: 'hsl(214, 50%, 6%)',
    //             grey5: '#E0E0E0',

                

    //         },
    //         font: {
    //             family: 'Roboto',
    //             size: '14px',
    //             height: '20px',
                
    //         },
    //     },
    //     menu: {
    //       extend: `padding-right :3px`,
    //         icons: {
    //             color:'#E0E0E0'
    //         },
         
          
         
    //     },
    // };
    const hamburgerMenu =  hamburger ?
     <Close size="medium" color="white"/> 
    : <HamMenu size="medium" color="white"/>;
   
    const onHamburgerHandler = ()=> {
      setHamburger(!hamburger)
     
    }


  return (
  //  <HeadingCon></HeadingCon>
    <header  className={classes.header}>
    <div className={classes.oshLogoCon}>
       
        <NavLink to="/" >
        <img className={classes.oshLogo} src={logo} alt="logo" />
            </NavLink>
      </div>
      <nav style={{color:'green'}}>
        <ul style={{display :`${width > 1024 || hamburger ?  'flex' : 'none'}` }} onClick={() => setHamburger(!hamburger)}>
          <li>
            <NavLink to="/" className={(NavData) => NavData.isActive ? classes.active : 'navLink'}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/summits" className={(NavData) => NavData.isActive ? classes.active : 'navLink' }>
              Summits
            </NavLink>
          </li>
    
{/*           
            <Menu
              pad="small"
              margin="none"
              size="small"
              dropAlign={{left: 'left', top: 'bottom'}}
              color="grey5"
              label="SUMMITS"
              items={[
                { label: "Summits", href: "/summits" },
                { label: "2020", href: "#" },
                { label: "2021", href: "#" },
              ]}
            /> */}
         
         
          <li>
            <NavLink to="/events" className={(NavData) => NavData.isActive ? classes.active : 'navLink'}>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/news" className={(NavData) => NavData.isActive ? classes.active : 'navLink'}>
              Blogs
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" className={(NavData) => NavData.isActive ? classes.active : 'navLink'}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/communitypage" className={(NavData) => NavData.isActive ? classes.active : 'navLink'}>
              Community 
            </NavLink>
          </li>
        </ul>
        
      </nav>
      <div onClick={onHamburgerHandler} className={classes.hamburgerCon}>
       {hamburgerMenu}
      </div>
    </header>

  );
};

export default MainNav;
