import styled from "styled-components";

export const GuideCon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:4rem 0;
  background-color: rgb(242, 242, 242);
`;
export const GuideWrapper = styled.div `
  display: flex;
  justify-content:space-between;
  width: 70%;

  @media screen and (max-width:679px){
    flex-direction:column;
  }
`;
export const GuideContent = styled.div `
  display:flex;
  flex-direction:column;
  align-items:flex-start;
`;
export const GuideBtn = styled.div `
  display:flex;
  align-items:flex-end;

  button {
    text-decoration:none;
    color: #fff;
    outline: none;
    border:  none;
    text-decoration: none;
    padding: 0.63rem 1rem;
    text-transform: uppercase;
    background-color: #1f1f1f;
    margin: 2rem;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 0.3rem;
    letter-spacing: 0.1em;
    cursor: pointer;
  }
    a{
        color:white;
        text-decoration:none;
        &:visited,&:hover,&:active{
     color:white;
   
    }
    
  }
`;