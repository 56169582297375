import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "../Ideology/Ideology.style";
import styled from "styled-components";
import {
  EventViewCon,
  EventViewWrapper,
  EventViewInfoCon,
  EventImgCon,
  EventImg,
  EventTextCon,
} from "./EventView.style";
import Africa1 from "../../assets/images/Africa1.png";

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

const EventsView = (props) => {
  return (
    <Fragment>
      <EventViewCon>
        <EventViewInfoCon>
          <h5 className="markdown">Summits</h5>
          <h3 className="main-header">AfricaOSH Summit</h3>
        </EventViewInfoCon>
        <EventViewWrapper>
          <EventImgCon>
            <EventImg src={Africa1} alt="console" />
          </EventImgCon>
          <EventTextCon>
            <p className="Wtext-paragraph">
              Africa Open Science
              Hardware hosts its annual gathering every year at a
              chosen African country. The summit hosts workshops, discussions
              and conferences on making, hacking and DIYBio for a wide range of
              stakeholders to engage with the processes of design, co-creation,
              problem-solving.
            </p>
          </EventTextCon>
        </EventViewWrapper>
        <Button>
          <StyledLink to="/summits">View more</StyledLink>
        </Button>
      </EventViewCon>
    </Fragment>
  );
};

export default EventsView;
