import { Fragment } from "react";
import DetailNews from "../../components/News/DetailNews";

import { useParams } from "react-router-dom";
import {
  ShortDesc,
  MainAboutInfoCon,
} from "../../components/AboutHeader/AboutHeader.style";
import FooterOsh from "../../components/FooterOsh/FooterOsh";

const NewsDetailPage = (props) => {
  const params = useParams();
  return (
    <Fragment>
      <MainAboutInfoCon>
        <ShortDesc style={{ marginTop: "1rem" }}>
          AfricaOSH news
        </ShortDesc>
      </MainAboutInfoCon>
      <DetailNews routeId={params.newsId} /> 
      <FooterOsh> </FooterOsh>
    </Fragment>
  );
};

export default NewsDetailPage;
