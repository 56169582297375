import { Box, } from "grommet";
import { Fragment } from "react";
import { BlogCon, NavHeader, BlogTextHeader } from "../News/News.style";
import summit2022 from "../../assets/images/newsummit.jpeg"
import {Link} from "react-router-dom"
import styled from "styled-components";
import ListSummit from "./ListSummit";
import { LinkButton } from "./SummitsSummary.style";
import EventImg from '../../assets/images/fff.jpg'
const StyledLink = styled(Link)`

  text-decoration:none;

`






const SummitsSummary = (props) => {

let btnContent =  <ListSummit Link={'/eventspage'} summitDesc={'AfricaOSH successfully organized its first in-person open science hardware workshop in Ghana on June 16, 2023. The workshop took place at the Kumasi Hive Event space and primarily catered to students from the Kwame Nkrumah University of Science and Technology, with one participant being a high school teacher from the Ghana Education Service (GES). A total of 13 individuals attended the workshop, consisting of 6 females and 9 males.'} date={" 20 July 2023 // 1:00 pm UTC"} ViewImage={EventImg}/>;



  return (
    <Fragment>
      <BlogCon>
        <NavHeader>
          <BlogTextHeader>
            <h5 className="markdown">event</h5>
          </BlogTextHeader>
          <BlogTextHeader>
            <StyledLink to={"/summits"} className="markdown">{"view more>>>"}</StyledLink>
          </BlogTextHeader>
        </NavHeader>
        <h3 className="main-header blackText">Upcoming Event</h3>
      </BlogCon>

      <Box
        direction="column"
        align="center"
        alignContent="center"
        alignSelf="center"
        
      >
     {btnContent}
<LinkButton ><a rel="noreferrer" target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLScEfpGFS2IGh1Mu5s1fPw55Yk4JPu2jI61pXD3o36D28iaaGA/viewform" style={{color:'white'}}>Apply Now</a></LinkButton>
      </Box>
    </Fragment>
  );
};

export default SummitsSummary;
